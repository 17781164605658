import { RootState } from 'base/reducer/reducer';
import { convertToMapItems } from 'components/Form/controls/MapPicker/MapPicker/helpers';
import { DateColumnModel, FormattedReferenceColumnModel, GenericColumnModel, MapOptionColumnModel, OptionColumnModel, StringColumnModel } from 'components/Table';
import { useGetStatusStyleCallback, useOrganizationalUnitCallback } from 'features/TableColumns/persistedHooks';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ProjectResponse } from 'services/tenantManagementService';
import { EntityPrefixEnum } from 'utils/commonHelper';
import { propertyOf } from 'utils/propertyOf';

export const defaultVisibleColumns = [
	propertyOf<ProjectResponse>('id'),
	propertyOf<ProjectResponse>('name'),
	propertyOf<ProjectResponse>('pmId'),
	propertyOf<ProjectResponse>('statusId'),
	propertyOf<ProjectResponse>('typeId'),
	propertyOf<ProjectResponse>('categoryId'),
	propertyOf<ProjectResponse>('organizationalUnitId'),
]

export const defaultDefaultProjectVisibleColumns = [
	...defaultVisibleColumns,
	'defaultProject'
]

export const useTableColumnsMemo = (defaultProjectId?: number): GenericColumnModel[] => {
	const persistedUser = useSelector((state: RootState) => state.persistedUser);
	const persistedProjectStatus = useSelector((state: RootState) => state.persistedProjectStatus);
	const persistedProjectCategory = useSelector((state: RootState) => state.persistedProjectCategory);
	const persistedProjectType = useSelector((state: RootState) => state.persistedProjectType);
	const persistedOrganizationalUnit = useSelector((state: RootState) => state.persistedOrganizationalUnit);

	const getOrganizationalUnit = useOrganizationalUnitCallback();
	const getStatusStyle = useGetStatusStyleCallback(persistedProjectStatus);

	const organizationalUnits = useMemo(
		() => convertToMapItems(persistedOrganizationalUnit.items || [], [], undefined),
		[persistedOrganizationalUnit]
	)

	return useMemo(
		() => [
			new FormattedReferenceColumnModel({
				id: propertyOf<ProjectResponse>('id'),
				title: 'Project ID',
				entityPrefix: EntityPrefixEnum.PROJECT,
				getStyle: (value: any) => value === defaultProjectId ? {
						color: 'var(--brand-color)',
						fontWeight: 'bold'
					} : {}
			}),
			new StringColumnModel({
				id: propertyOf<ProjectResponse>('name'),
				title: 'Project name'
			}),
			new OptionColumnModel({
				id: propertyOf<ProjectResponse>('pmId'),
				title: 'Project manager',
				items: persistedUser.items,
				getItemId: (option) => option.id,
				getItemText: (option) => `${option.firstName} ${option.lastName}`
			}),
			new OptionColumnModel({
				id: propertyOf<ProjectResponse>('statusId'),
				title: 'Project status',
				getStyle: (value: any) => getStatusStyle(value),
				items: persistedProjectStatus.items,
				getItemId: (option) => option.id,
				getItemText: (option) => option.name
			}),
			new OptionColumnModel({
				id: propertyOf<ProjectResponse>('typeId'),
				title: 'Project type',
				items: persistedProjectType.items,
				getItemId: (option) => option.id,
				getItemText: (option) => option.name
			}),
			new OptionColumnModel({
				id: propertyOf<ProjectResponse>('categoryId'),
				title: 'Project category',
				items: persistedProjectCategory.items,
				getItemId: (option) => option.id,
				getItemText: (option) => option.name
			}),
			new StringColumnModel({
				id: propertyOf<ProjectResponse>('description'),
				title: 'Project description'
			}),
			new MapOptionColumnModel({
				id: propertyOf<ProjectResponse>('organizationalUnitId'),
				title: 'Organizational Unit',
				items: organizationalUnits,
				getItemId: (option) => option.id,
				getItemText: (option) => getOrganizationalUnit(option.id, organizationalUnits)
			}),
			new DateColumnModel({
				id: propertyOf<ProjectResponse>('from'),
				title: 'Project start'
			}),
			new DateColumnModel({
				id: propertyOf<ProjectResponse>('to'),
				title: 'Project end'
			})
		],
		[persistedUser.items, persistedProjectStatus.items, persistedProjectType.items, persistedProjectCategory.items, organizationalUnits, defaultProjectId, getStatusStyle, getOrganizationalUnit]
	)
}
