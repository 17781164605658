import { LockIcon } from 'components/icons/icons';
import styles from './styles.module.scss'

type Props = {
	content: string
}

export const FrozenElement = ({ content }: Props) => {
	return (
		<div className={styles.frozen_element}>
			{content}
			<LockIcon className={styles.icon} width={11} height={11} fill='currentColor' />
		</div>
	);
};
