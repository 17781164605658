import { Draggable } from 'react-beautiful-dnd';
import styles from './styles.module.scss'

type Props = {
	id: string
	content: string
	index: number
}

export const Element = ({ id, content, index }: Props) => (
	<Draggable draggableId={id} index={index}>
		{(provided, snapshot) => (
			<div className={`${styles.draggable_element} ${snapshot.isDragging ? styles.dragging : ''}`}
				{...provided.draggableProps}
				{...provided.dragHandleProps}
				ref={provided.innerRef}
			>
				{content}
			</div>
		)}
	</Draggable>
)
