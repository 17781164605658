import { forwardRef, useCallback, useMemo, useState } from "react";
import { RowComponent, TabulatorFull as Tabulator } from 'tabulator-tables';
import { GenericSortModel } from "services/tenantManagementService"
import { BaseColumnModel, BaseTable } from "../BaseTable";
import { FilterModel } from "../BaseTable/Filter/Filter";
import { noop } from "utils/commonHelper";
import { filterData, paginateData, sortData } from "./filterSortPageHelpers";
import { sumCalc } from "./footerCalculationHelpers";
import { useFallbackRef } from "utils/refUtils";

export type LocalTableProps = {
	columns: BaseColumnModel[]
	data: any[]
	limit?: number
	rowSelectionChanged?: (data: any[], selectedRows: RowComponent[]) => void
	reorderColumns?: (newColumns: string[]) => void
	cellEdited?: (cell: any) => void
	groupClick?: (e: any, group: any) => void
	isLoading?: boolean
	options?: any
	hasPagination?: boolean
	compact?: boolean
	withoutPaddingBottom?: boolean
}

export const LocalTable = forwardRef((props: LocalTableProps, ref: React.ForwardedRef<Tabulator>) => {
	const {
		columns,
		data,
		limit,
		rowSelectionChanged,
		reorderColumns,
		cellEdited,
		groupClick,
		isLoading,
		options,
		hasPagination,
		compact,
		withoutPaddingBottom
	} = props;

	const [filters, setFilters] = useState<FilterModel[]>([])
	const [offset, setOffset] = useState(0);
	const [sort, setSort] = useState(new GenericSortModel())

	const tabulatorRef = useFallbackRef(ref);

	const setSortCallback = useCallback(
		(dbFilterFieldPath: string) => {
			setSort((state) => {
				return new GenericSortModel({
					property: dbFilterFieldPath,
					isAsc: state.property === dbFilterFieldPath ? !state.isAsc : true
				});
			})
			setOffset(0);
		},
		[]
	)

	const setFiltersCallback = useCallback(
		(newFilters: FilterModel[]) => {
			setFilters(newFilters);
			setOffset(0);
		},
		[]
	)

	const convertedColumns: BaseColumnModel[] = useMemo(
		() => {
			// this is not the best place for filtering data, because in 99% we don't have bottomCalc
			// so if this shows to be to slow, refactor this code a little
			const filteredData = filterData(data, filters, columns);

			return columns.reduce(
				(result: BaseColumnModel[], column) => {
					if (column.visible) {
						const item: BaseColumnModel = column;

						if (item.bottomCalc === 'sum') {
							item.bottomCalc = sumCalc.bind(null, filteredData, column);
						}

						result.push(item)
					}

					return result;
				},
				[]
			)
		},
		[columns, data, filters]
	)

	const pagination = useMemo(
		() => {
			if (!hasPagination) {
				return undefined;
			}

			return {
				offset,
				limit,
				count: data.length,
				onChange: setOffset
			}
		},
		[hasPagination, offset, limit, data.length]
	)

	const rowsData = useMemo(
		() => {
			const filteredData = filterData(data, filters, convertedColumns);
			const sortedData = sortData(filteredData, sort, convertedColumns);
			const paginatedData = hasPagination ? paginateData(sortedData, offset, limit) : sortedData;
			return paginatedData;
		},
		[data, sort, filters, limit, offset, convertedColumns, hasPagination]
	)

	return (
		<BaseTable
			ref={tabulatorRef}
			fetchFunction={noop}
			isLoading={!!isLoading}
			columns={convertedColumns}
			rowsData={rowsData}
			filters={filters}
			setFilters={setFiltersCallback}
			sort={sort}
			setSort={setSortCallback}
			pagination={pagination}
			reorderColumns={reorderColumns}
			rowSelectionChanged={rowSelectionChanged}
			options={options}
			cellEdited={cellEdited}
			validationFailed={noop}
			cellEditCancelled={noop}
			groupClick={groupClick}
			compact={compact}
			withoutPaddingBottom={withoutPaddingBottom}
		/>
	)
})
