import styles from './tooltip.module.scss'

type Props = {
	content: string
	topPosition: number
	leftPosition: number
}

export const Tooltip = ({ content, topPosition, leftPosition }: Props) => {
	return (
		<div className={styles.content} style={{ position: 'fixed', top: `${topPosition}px`, left: `${leftPosition}px` }}>
			{content}
		</div>
	)
}
