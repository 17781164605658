import { useCallback } from 'react';
import NumberFormat, { NumberFormatProps, NumberFormatValues } from 'react-number-format';
import { ControlsCommonProps } from '../../fields';
import styles from './inputNumber.module.scss';
import Spinner, { ClipSpinner } from 'components/Spinner';
import { MaxLength } from '../../addons';
import { InfoPopup, POSITION } from 'components/Popup';
import { Subtract } from 'utility-types';

export const currencyDefaultProps = {
    thousandSeparator: true,
	decimalScale: 2,
	fixedDecimalScale: true,
	align: 'left' as any
}

export const percentDefaultProps = {
	decimalScale: 2,
	fixedDecimalScale: true,
	align: 'left' as any,
	suffix: '%'
}

export type InputNumberProps = ControlsCommonProps<number | undefined> & Subtract<NumberFormatProps, { onChange?, size? }> & {
	min?: number
	max?: number
	suffix?: string
	explanation?: string
	maxLength?: number
	isAllowed?: (values: NumberFormatValues) => boolean
	align?: 'left' | 'center' | 'right'
	loading?: boolean
	size?: 'medium' | 'small'
	focus?: boolean
}

export const InputNumber = ({ value, onChange, onBlur, disabled, min, max, explanation, maxLength, isAllowed, align, loading, size = 'medium', focus = false, ...props }: InputNumberProps) => {
	const onChangeCallback = useCallback(
		(values: NumberFormatValues) => {
			let value = values.floatValue;
			// when prefix='-' and allowNegative=false, library returns negative value (bug in library), so here we fix that
			if (value && props.prefix === '-') {
				value = Math.abs(value);
			}
			onChange && onChange(value);
		},
		[onChange, props.prefix]
	)

	const isAllowedCallback = useCallback(
		(values: NumberFormatValues) => {
			const floatValue = values.floatValue;

			if (floatValue === undefined) {
				return true;
			}

			if (min !== undefined && min > floatValue) {
				return false;
			}

			if (max !== undefined && max < floatValue) {
				return false;
			}

			if (isAllowed) {
				return isAllowed(values);
			}

			return true;
		},
		[min, max, isAllowed]
	)

	return (
		<div className={`${styles.wrapper} ${size === 'small' ? styles.small : ''}`}>
			<div className={styles.container}>
				<NumberFormat
					className={`${styles.inputNumber} ${size === 'small' ? styles.small : ''} ${align ? styles['align_' + align] : ''}`}
					onValueChange={onChangeCallback}
					onBlur={onBlur}
					disabled={disabled || loading}
					{...props}
					isAllowed={isAllowedCallback}
					value={value === undefined ? '' : value}
					maxLength={maxLength ? (maxLength + (props.suffix ? props.suffix?.length : 0)) : undefined}
					autoFocus={focus}
				/>
				{/* max length */}
				{maxLength &&
					<div className={styles.max_length}>
					<MaxLength
						length={value ? value.toString().length : 0}
						maxLength={maxLength}
					/>
					</div>
				}
			</div>
			{/* explanation */}
			{explanation &&
				<div className={styles.explanation}>
					<InfoPopup
						message={explanation}
						position={POSITION.TOP_CENTER}
					/>
				</div>
			}
			{/* loading */}
			{loading &&
				<div style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}>
					<Spinner>
						<ClipSpinner size={20} />
					</Spinner>
				</div>
			}
		</div>
	)
}
