import { GenericFilterOperationEnum } from 'services/tenantManagementService';
import { AndOrEnum } from '../../filtersHelper';

export class TimeFilterModel {
	filterType1?: GenericFilterOperationEnum;
	filterValue1?: number;
	filterType2?: GenericFilterOperationEnum;
	filterValue2?: number;
	additionalFilter: AndOrEnum = AndOrEnum.And;

	constructor(filterModel?: TimeFilterModel) {
		if (filterModel) {
			this.filterType1 = filterModel.filterType1;
			this.filterValue1 = filterModel.filterValue1;
			this.filterType2 = filterModel.filterType2;
			this.filterValue2 = filterModel.filterValue2;
			this.additionalFilter = filterModel.additionalFilter;
		}
	}
}
