import { FilterType } from '../models';

// find first base filter by columnId
export const findFilter = (filters?: FilterType[], columnId?: string) => {
	return filters?.find(filter => filter.columnId === columnId ||
		filter.orFilters?.find(orFilter => orFilter.columnId === columnId)
	)
}

export const findAllFilters = (filters: FilterType[], columnId?: string) => {
	return filters.filter(filter => filter.columnId === columnId ||
		filter.orFilters?.find(orFilter => orFilter.columnId === columnId)
	)
}

export enum AndOrEnum {
	And = 'And',
	Or = 'Or'
}
