import { useCallback, useMemo, useState } from 'react';
import { AttachmentIcon, LinkIcon } from 'components/icons/icons';
import { getAbsoluteUrl } from './helper';
import styles from './linkCell.module.scss'
import FileSaver from 'file-saver';
import { OverlaySpinner } from 'components/Spinner';

type AttachmentType = {
	id: number
	name: string
	uri?: string | undefined;
}

type Props = {
	value: string | AttachmentType[]
	isCellEditable: boolean | undefined
	type: 'link' | 'attachment'
	route?: string
	downloadAttachment?: (attachmentId: number) => Promise<any>
	isSimpleAttachment?: boolean
}

export const LinkCell = ({ value, isCellEditable = false, type, route, downloadAttachment, isSimpleAttachment }: Props) => {
	const [isOpen, setIsOpen] = useState(false);
	const [downloadingId, setDownloadingId] = useState<number | string>();

	const handleOpenCallback = useCallback(
		(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
			// don't select row
			e.stopPropagation();

			setIsOpen(true);
		},
		[]
	)

	const handleCloseCallback = useCallback(
		() => {
			if (!downloadingId) {
				setIsOpen(false);
			}
		},
		[downloadingId]
	)

	const handleOnLinkClickCallback = useCallback(
		async (e: React.MouseEvent<HTMLDivElement, MouseEvent>, link: string | number) => {
			// don't select row
			e.stopPropagation();

			if (type === 'link' && typeof link === 'string') {
				const url = getAbsoluteUrl(link);
				window.open(url, '_blank');
			}

			if (type === 'attachment' && downloadAttachment) {
				// if attachment is simple, then link value is 'string' as file name. We pass 0 as argument, and don't use 'id' for bind function in this case.
				const id = typeof link === 'number' ? link : 0;
				setDownloadingId(link);
				const response = await downloadAttachment(id);

				if (response.status === 200) {
					FileSaver.saveAs(response.data, response.fileName);
				}

				setDownloadingId(undefined);
			}

			handleCloseCallback();
		},
		[handleCloseCallback, type, downloadAttachment]
	)

	const renderItemsMemo = useMemo(
		() => {
			if (isSimpleAttachment) {
				return [];
			}

			if (type === 'link') {
				const links = (value as string).split(';').filter(x => !!x);
				const routes = route?.split(';').filter(x => !!x) || [];

				return links.map((link, i) => {
					const route = routes[i];
					return (
						<div className={styles.link_item} key={i}>
							{route ?
								<a href={route} className={styles.link} rel="noreferrer">
									{link}
								</a>
								:
								<div className={styles.link} onClick={(e) => handleOnLinkClickCallback(e, link)} title={link}>
									{link}
								</div>
							}
						</div>
					)
				});
			} else {
				return (value as AttachmentType[]).map((attachment) => (
					<div className={styles.link_item} key={attachment.id}>
						<div className={styles.link} onClick={(e) => handleOnLinkClickCallback(e, attachment.id)}>
							{attachment.name}
						</div>
						{downloadingId === attachment.id &&
							<OverlaySpinner useBrandColor />
						}
					</div>
				));
			}
		},
		[handleOnLinkClickCallback, type, value, downloadingId, isSimpleAttachment, route]
	)

	const linkCount = useMemo(
		() => {
			return type === 'link' ?
				(value as string).split(';').filter(x => !!x).length :
				(value as AttachmentType[]).length;
		},
		[value, type]
	)

	if (isSimpleAttachment) {
		return (
			<div className={styles.simple_attachment} onClick={(e) => handleOnLinkClickCallback(e, value as string)}>
				<AttachmentIcon className={styles.icon} width={12} height={12} fill='currentColor' />
				<span>{value as string}</span>
				{downloadingId === value as string && <OverlaySpinner size={16} useBrandColor />}
			</div>
		)
	}

	return (
		<>
			<div className={styles.container} onClick={handleOpenCallback}>
				{type === 'link' ?
					<LinkIcon width={12} height={12} fill='currentColor' /> :
					<AttachmentIcon width={12} height={12} fill='currentColor' />
				}
				<span className={styles.link_badge}>{linkCount}</span>
			</div>
			{isOpen && !isCellEditable &&
				<div className={styles.dropdown_content} onMouseLeave={handleCloseCallback}>
					{renderItemsMemo}
				</div>
			}
		</>
	)
}
