import { TimePicker } from 'components/Form'

type Props = {
	value: number
	onChange(value: number): void
	onBlur(): void
	loading: boolean
}

export const TimeEditableCell = ({ value, onChange, onBlur, loading }: Props) => {
	return (
		<TimePicker
			value={value}
			onChange={onChange}
			onBlur={onBlur}
			loading={loading}
			placeholder='--:-- --'
			size='small'
			focus
		/>
	)
}
