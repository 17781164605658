import moment from 'moment';
import { GenericFilterModel, MonthEnum, WeekDayEnum } from 'services/tenantManagementService';
import { MeridiemTimeEnum } from 'utils/MeridiemTimeEnum';

export const getTimeDifferenceBetweenDates = (date1: Date, date2: Date) => {
	const differenceInTime = date2.getTime() - date1.getTime();
	return differenceInTime;
}

export const getNumberOfDaysBetweenDates = (date1: Date, date2: Date) => {
	const date1WithoutTIme = new Date(date1.getFullYear(), date1.getMonth(), date1.getDate());
	const date2WithoutTIme = new Date(date2.getFullYear(), date2.getMonth(), date2.getDate());
	return Math.ceil(
		getTimeDifferenceBetweenDates(date1WithoutTIme, date2WithoutTIme) / (1000 * 3600 * 24)
	)
}

export const getNumberOfHoursBetweenDates = (date1: Date, date2: Date) => {
	return Math.ceil(
		getTimeDifferenceBetweenDates(date1, date2) / (1000 * 3600)
	);
};

export const getNumberOfMinutesBetweenDates = (date1: Date, date2: Date) => {
	return Math.ceil(
		getTimeDifferenceBetweenDates(date1, date2) / (1000 * 60)
	)
}

export const getNumberOfWeeksBetweenDates = (date1: Date, date2: Date) => {
	const date1WithoutTIme = new Date(date1.getFullYear(), date1.getMonth(), date1.getDate());
	const date2WithoutTIme = new Date(date2.getFullYear(), date2.getMonth(), date2.getDate());
	return Math.ceil(
		getTimeDifferenceBetweenDates(date1WithoutTIme, date2WithoutTIme) / (1000 * 3600 * 24 * 7)
	);
};

export const getNumberOfMonthsBetweenDates = (date1: Date, date2: Date) => {
	const differenceMonth = date2.getMonth() - date1.getMonth();
	const differenceYear = date2.getFullYear() - date1.getFullYear();
	return differenceMonth + differenceYear * 12;
};

export const getDateIncrementByNumberOfDays = (date: Date, days: number) => {
	const dateWithTime = new Date(date.getTime());
	dateWithTime.setTime(dateWithTime.getTime() + days * 24 * 3600 * 1000);
	return dateWithTime;
};

export const getDateIncrementByNumberOfMonths = (date: Date, value: number) => {
	const dateWithoutTime = new Date(date.getFullYear(), date.getMonth(), date.getDate());
	dateWithoutTime.setMonth(dateWithoutTime.getMonth() + value);
	return dateWithoutTime;
};

export const getDateIncrementByNumberOfWeeks = (date: Date, value: number) => {
	const dateWithoutTime = new Date(date.getFullYear(), date.getMonth(), date.getDate());
	dateWithoutTime.setDate(dateWithoutTime.getDate() + 7 * value);
	return dateWithoutTime;
};

export const getDateIncrementByNumberOfHours = (date: Date, hours: number) => {
	const dateWithTime = new Date(date.getTime());
	dateWithTime.setTime(dateWithTime.getTime() + hours * 3600 * 1000);
	return dateWithTime;
};

export const getDateIncrementByNumberOfMinutes = (date: Date, minutes: number) => {
	const dateWithTime = new Date(date.getTime());
	dateWithTime.setTime(dateWithTime.getTime() + minutes * 60 * 1000);
	return dateWithTime;
};

export const getDateIncrementByNumberOfHoursBasedOnCalendarSettingsWorkTime = (date: Date, value: number, workTime: number) => {
	const differenceWorkTime = 24 - workTime;
	const numberOfDays = Math.floor(value / workTime);
	const newValue = value + (differenceWorkTime * numberOfDays);

	const dateWithTime = new Date(date.getTime());
	dateWithTime.setTime(dateWithTime.getTime() + (newValue * 3600 * 1000));
	return dateWithTime;
};

export const getNumberOfHoursBetweenDatesBasedOnCalendarSettingsWorkTime = (date1: Date, date2: Date, workTime: number) => {
	const differenceWorkTime = 24 - workTime;
	const numberOfDays = getNumberOfDaysBetweenDates(date1, date2);
	return (
		Math.ceil(getTimeDifferenceBetweenDates(date1, date2) / (1000 * 3600)) - (differenceWorkTime * numberOfDays)
	);
};

export const getNumberOfDaysBetweenDatesWithoutWeekends = (date1: Date, date2: Date) => {
	const startDate = new Date(date1);
	let workingDays = 0;

	while (startDate < date2) {
		const day = startDate.getDay();
		if (day !== 0 && day !== 6) {
			workingDays++;
		}
		startDate.setDate(startDate.getDate() + 1);
	}
	return workingDays;
};

export const getDateIncrementByNumberOfDaysWithoutWeekends = (date: Date, value: number) => {
	const newDate = new Date(date);
	let workingDays = value;

	while (workingDays > 0) {
		const day = newDate.getDay();
		if (day !== 0 && day !== 6) {
			workingDays--;
		}
		newDate.setDate(newDate.getDate() + 1);
	}

	return newDate;
};

export const getNumberOfHoursBetweenDatesWithoutWeekends = (date1: Date, date2: Date) => {
	const startDate = new Date(date1);
	let workingHours = 0;

	while (startDate < date2) {
		const day = startDate.getDay();
		if (day !== 0 && day !== 6) {
			workingHours++;
		}
		startDate.setTime(startDate.getTime() + (3600 * 1000));
	}
	return workingHours;
};

export const getDateIncrementByNumberOfHoursWithoutWeekends = (date: Date, value: number) => {
	const newDate = new Date(date.getTime());
	let workingHours = value;

	while (workingHours > 0) {
		const day = newDate.getDay();
		if (day !== 0 && day !== 6) {
			workingHours--;
		}
		newDate.setTime(newDate.getTime() + (3600 * 1000));
	}

	return newDate;
};

export const getNumberOfHoursBetweenDatesWithoutWeekendsBasedOnCalendarSettingsWorkTime = (date1: Date, date2: Date, workTime: number) => {
	const differenceWorkTime = 24 - workTime;
	const numberOfHours = getNumberOfHoursBetweenDatesWithoutWeekends(date1, date2);
	const numberOfDays = Math.floor(numberOfHours / 24);
	return getNumberOfHoursBetweenDatesWithoutWeekends(date1, date2) - differenceWorkTime * numberOfDays;
};

export const getDateIncrementByNumberOfHoursWithoutWeekendsBasedOnCalendarSettingsWorkTime = (date: Date, value: number, workTime: number) => {
	const differenceWorkTime = 24 - workTime;
	const numberOfDays = Math.floor(value / workTime);
	const newValue = value + (differenceWorkTime * numberOfDays);
	return getDateIncrementByNumberOfHoursWithoutWeekends(date, newValue);
};

// TODO: should use new Intl.DateTimeFormat('en-US'
export const formatDate = (date?: Date) => {
	if (!date) {
		return '';
	}
	return moment(date).format('MM/DD/YYYY');
}

// TODO: should use new Intl.DateTimeFormat('en-US'
export const formatDateTime = (date?: Date) => {
	if (!date) {
		return '';
	}
	return moment(date).format('MM/DD/YYYY hh:mm A');

}

export const formatServerDate = (value?: Date) => {
	if (!value) {
		return '';
	}

	const fullYear = value.getFullYear();
	const month = value.getMonth();
	const dayOfTheMonth = value.getDate();

    return fullYear + '-' +
        (month < 9 ? ('0' + (month + 1)) : (month + 1)) + '-' +
        (dayOfTheMonth < 10 ? ('0' + dayOfTheMonth) : dayOfTheMonth);
}

// const parseTimeStringToMilliseconds = (value: string) => {
// 	const newValueSplit = value.split(':');
// 	const hours = newValueSplit[0] ? parseInt(newValueSplit[0]) : undefined;
// 	const minutes = newValueSplit[1] ? parseInt(newValueSplit[1]) : undefined;

// 	if (hours === undefined || minutes === undefined) {
// 		return;
// 	}

// 	const timeInMinutes = hours * 60 + minutes;
// 	const timeInSeconds = timeInMinutes * 60;
// 	const timeInMiliseconds = timeInSeconds * 1000;
// 	return timeInMiliseconds;
// }

// export const parseTimeStringToTicks = (value: string) => {
// 	const timeInMiliseconds = parseTimeStringToMilliseconds(value);
// 	if (!timeInMiliseconds) {
// 		return;
// 	}
// 	return timeInMiliseconds * 10000;
// }

// TODO: should use new Intl.DateTimeFormat('en-US', { timeStyle: 'short' })
export const formatTime = (value?: number) => {
	if (value === undefined) {
		return '';
	} else {
		const timeInMiliseconds = value / 10000;
		const timeInSeconds = timeInMiliseconds / 1000;
		const timeInMinutes = Math.floor(timeInSeconds / 60);

		const minutes = timeInMinutes % 60;
		let hours = Math.floor(timeInMinutes / 60);
		let amOrPm = 'AM';
		if (hours >= 12) {
			amOrPm = 'PM';
		}

		if (hours > 12) {
			hours -= 12;
		} else if (hours === 0) {
			hours = 12;
		}

		const minutesString = String(minutes).padStart(2, '0');
		const hoursString = String(hours).padStart(2, '0');

		return `${hoursString}:${minutesString} ${amOrPm}`
	}
}

// TODO: should use new Intl.DateTimeFormat('en-US', { timeStyle: 'short' })
export const formatTimeWithoutAmOrPm = (value?: number) => {
	if (value === undefined) {
		return '';
	} else {
		const timeInMiliseconds = value / 10000;
		const timeInSeconds = timeInMiliseconds / 1000;
		const timeInMinutes = Math.floor(timeInSeconds / 60);

		const minutes = timeInMinutes % 60;
		let hours = Math.floor(timeInMinutes / 60);

		const minutesString = String(minutes).padStart(2, '0');
		const hoursString = String(hours).padStart(2, '0');

		return `${hoursString}:${minutesString}`;
	}
}

// export const availableHours = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

export const availableMinutes = [
	0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10,
	11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
	21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
	31, 32, 33, 34, 35, 36, 37, 38, 39, 40,
	41, 42, 43, 44, 45, 46, 47, 48, 49, 50,
	51, 52, 53, 54, 55, 56, 57, 58, 59
];

// export const availableAmPm = [
// 	MeridiemTimeEnum.AM,
// 	MeridiemTimeEnum.PM
// ];

export const convertTicksToMiliSeconds = (value: number) => {
	return value / 10000;
}

export const convertTicksToTime = (value: number) => {
	const timeInMilliSeconds = convertTicksToMiliSeconds(value);
	const timeInSeconds = timeInMilliSeconds / 1000;
	const timeInMinutes = Math.floor(timeInSeconds / 60);

	const minutes = timeInMinutes % 60;
	let hours = Math.floor(timeInMinutes / 60);

	return {
		minutes,
		hours
	};
}

// export const convertTicksToMeridiemTime = (value: number) => {
// 	let { hours, minutes } = convertTicksToTime(value);

// 	const amOrPm = hours >= 12 ? MeridiemTimeEnum.PM : MeridiemTimeEnum.AM;
// 	if (hours > 12) {
// 		hours -= 12;
// 	} else if (hours === 0) {
// 		hours = 12;
// 	}

// 	return {
// 		hours,
// 		minutes,
// 		amOrPm
// 	}
// }

export const convertTimeToTicks = (hours: number, minutes: number) => {
	const timeInHours = hours;
	const timeInMinutes = timeInHours * 60 + minutes;
	const timeInSeconds = timeInMinutes * 60;
	const timeInMilliSeconds = timeInSeconds * 1000;
	const timeInTicks = timeInMilliSeconds * 10000
	return timeInTicks;
}

export const convertMeridiemTimeToTicks = (hours: number, minutes: number, meridiemTime: MeridiemTimeEnum) => {
	if (meridiemTime === MeridiemTimeEnum.PM && hours < 12) {
		hours += 12;
	} else if (meridiemTime === MeridiemTimeEnum.AM && hours === 12) {
		hours = 0;
	}

	const convertedTime = convertTimeToTicks(hours, minutes);
	if (!convertedTime) {
		return;
	}
	return convertedTime;
}

export const minutesToDhm = (minutes: number, dayLastInMinutes: number = 60*24) => {
	const d = Math.floor(minutes / dayLastInMinutes);
	const h = Math.floor(minutes % dayLastInMinutes / 60);
	const m = Math.floor(minutes % 60);

	const dDisplay = d > 0 ? d + (d === 1 ? ' day ' : ' days ') : '';
	const hDisplay = h + 'h ';
	const mDisplay = m + 'min ';
	return dDisplay + hDisplay + mDisplay;
}

export const minutesToHm = (minutes: number) => {
	const h = Math.floor(minutes / 60);
	const m = Math.floor(minutes % 60);

	const hDisplay = h > 0 ? h + 'h ' : '';
	const mDisplay = m + 'min ';
	return hDisplay + mDisplay;
}

const monthsOfYear = [
	MonthEnum.January,
	MonthEnum.February,
	MonthEnum.March,
	MonthEnum.April,
	MonthEnum.May,
	MonthEnum.June,
	MonthEnum.July,
	MonthEnum.August,
	MonthEnum.September,
	MonthEnum.October,
	MonthEnum.November,
	MonthEnum.December,
];

export const getMonthEnumByDate = (date: Date) => monthsOfYear[date.getMonth()]

export const getMonthIndexByMonthEnum = (monthEnum: MonthEnum) => monthsOfYear.indexOf(monthEnum);

const daysOfWeek = [
	WeekDayEnum.Sunday,
	WeekDayEnum.Monday,
	WeekDayEnum.Tuesday,
	WeekDayEnum.Wednesday,
	WeekDayEnum.Thursday,
	WeekDayEnum.Friday,
	WeekDayEnum.Saturday
];

export const getWeekDayEnumByDate = (date: Date) => daysOfWeek[date.getDay()];


export const getDaysOfWeekSorted = () => {
	const result = [...daysOfWeek];
	result.push(result.shift()!);
	return result;
}

export const getMondayOfWeekByDate = (date: Date) => {
	const currentDay = date.getDay();

	let substractDays = currentDay - 1;
	if (substractDays < 0) {
		// Sunday is 0, so we substract 6 days to get monday
		substractDays = 6;
	}
	const monday = new Date(date);
	monday.setDate(monday.getDate() - substractDays);
	monday.setHours(0,0,0,0);
	return monday;
}

export const getDifferenceInDaysFromMonday = (weekDayEnum: WeekDayEnum) => {
	if (weekDayEnum === WeekDayEnum.Sunday) {
		return 6;
	}
	return daysOfWeek.indexOf(weekDayEnum) - 1;
}

// bug 229: server accepts yyyy-mm-dd, but datepicker works with mm/dd/yyyy
export const fixDateFilters = (filters: GenericFilterModel[] | undefined) => filters?.map(
	filter =>  new GenericFilterModel({
		...filter,
		value: filter.value instanceof Date ? formatServerDate(filter.value) : filter.value,
		orFilters: filter.orFilters?.map(orFilter => new GenericFilterModel({
			...orFilter,
			value: orFilter.value instanceof Date ? formatServerDate(orFilter.value) : orFilter.value,
		}))
	})
)

// removes last character 'Z' from Date string so time is from start of the day
export const removeZoneFromDate = (date: Date | undefined) => {
	if (!date) {
		return;
	}

	const dateIsoString = date.toISOString();
	return new Date(dateIsoString.substring(0, dateIsoString.length - 1));
}

// to quick fix JSON.serialize when we need to send Date without time for example
export const getDateWithTimezoneOffset = (date: Date | undefined) => {
	if (!date) {
		return;
	}

	return new Date(date.getTime() - (date.getTimezoneOffset() * 60000));
}

const getDaysInMonth = (month: number, year: number) => {
	return 32 - new Date(year, month, 32).getDate();
}

const isWeekday = (year: number, month: number, day: number) => {
    const weekDayNumber = new Date(year, month, day).getDay();
    return weekDayNumber !== 0 && weekDayNumber !== 6; // sunday and saturday
}

export const getWeekdaysInMonth = (month: number, year: number) => {
    var days = getDaysInMonth(month, year);
    var weekdays = 0;
    for(var i=0; i< days; i++) {
        if (isWeekday(year, month, i+1))
            weekdays++;
    }
    return weekdays;
}

export const getLastDayOfMonth = (date: Date | undefined) => {
	if (!date) {
		return
	}
	return new Date(date.getFullYear(), date.getMonth()+1, 0);
}
