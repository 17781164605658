export const capitalizeFirstLetter = (value: string | undefined) => {
	if (!value) {
		return value;
	}

	return value.charAt(0).toUpperCase() + value.slice(1);
}

export const sortByString = (values: any[], propName: string) => {
	return values.sort((a, b) => {
		if (a[propName]?.toUpperCase() < b[propName]?.toUpperCase()) {
			return -1;
		}
		if (a[propName]?.toUpperCase() > b[propName]?.toUpperCase()) {
			return 1;
		}

		return 0;
	});
}

export const convertHtmlToText = (html: string | undefined) => {
	if (!html) {
		return '';
	}

	const parsedString = new DOMParser().parseFromString(html, 'text/html');
	return parsedString.body.textContent || '';
}
