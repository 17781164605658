import { memo } from 'react';
import { BooleanFilter } from './BooleanFilter/BooleanFilter';
import { DateFilter } from './DateFilter/DateFilter';
import { NumberFilter } from './NumberFilter/NumberFilter';
import { OptionsFilter } from './OptionsFilter/OptionsFilter';
import { StringFilter } from './StringFilter/StringFilter';
import { MapFilter } from './MapFilter/MapFilter';
import { GenericColumnModel, BooleanColumnModel, CurrencyColumnModel, DateColumnModel, FilterType, FormattedReferenceColumnModel, MapOptionColumnModel, NumberColumnModel, OptionColumnModel, OptionsColumnModel, SemaphoreColumnModel, StringColumnModel, DurationColumnModel, TimeColumnModel } from '../../models';
import { SemaphoreFilter } from './SemaphoreFilter/SemaphoreFilter';
import { TimeFilter } from './TimeFilter/TimeFilter';

type Props = {
	column: GenericColumnModel
	filters: FilterType[]
	onSave: (filters: FilterType[]) => void
	onCancel: () => void
}

const FilterElement = ({ column, ...rest }: Props) => {
	if (column instanceof FormattedReferenceColumnModel) {
		return <StringFilter column={column} {...rest} />;
	} else if (column instanceof DateColumnModel) {
		return <DateFilter column={column} {...rest} />;
	} else if (column instanceof OptionsColumnModel || column instanceof OptionColumnModel) {
		return <OptionsFilter column={column} {...rest} />;
	} else if (column instanceof MapOptionColumnModel) {
		return <MapFilter column={column} {...rest} />
	} else if (column instanceof BooleanColumnModel) {
		return <BooleanFilter column={column} {...rest} />
	} else if (column instanceof NumberColumnModel || column instanceof CurrencyColumnModel || column instanceof DurationColumnModel) {
		return <NumberFilter column={column} {...rest} />
	} else if (column instanceof SemaphoreColumnModel) {
		return <SemaphoreFilter column={column} {...rest} />
	} else if (column instanceof TimeColumnModel) {
		return <TimeFilter column={column} {...rest} />
	} else if (column instanceof StringColumnModel) {
		return <StringFilter column={column} {...rest} />
	} else {
		return <StringFilter column={column} {...rest} />
	}
}

export default memo(FilterElement)
