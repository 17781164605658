import { GenericColumnModel, OptionColumnModel, OptionsColumnModel, MapOptionColumnModel, StringColumnModel } from "./models";

export const getColumnMaxInitialWidth = (column: GenericColumnModel): number => {
	switch (true) {
		case column instanceof OptionColumnModel || column instanceof OptionsColumnModel:
			return 300;
		case column instanceof MapOptionColumnModel:
			return 300;
		case column instanceof StringColumnModel:
			return 500;
		default:
			return 150;
	}
};
