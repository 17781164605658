export const floatNumberEditor = function(cell: any, onRendered: any, success: any, cancel: any, editorParams: any){
    const editor = document.createElement("input");

    editor.setAttribute("type", "number");

    editor.style.padding = "3px";
    editor.style.width = "100%";
    editor.style.boxSizing = "border-box";

    editor.value = cell.getValue();

    onRendered(function(){
        editor.focus();
		editor.style.height = "100%";
    });

    function successFunc(){
		const parsedValue = parseFloat(editor.value);
		if (parsedValue === 0) {
			success(0);
			return;
		}

		if (!parsedValue) {
			success(undefined);
			return;
		}

        success(parsedValue);
    }

    editor.addEventListener("change", successFunc);
    editor.addEventListener("blur", successFunc);

	editor.addEventListener("keydown", function(e) {
		switch (e.key) {
			case 'Tab':
				e.stopPropagation();
				break;
			case 'Escape':
				cancel();
				break;
			case 'Enter':
				successFunc();
				break;
			default:
				return;
		}
	});

    //return the editor element
    return editor;
};


// DEPRECATED: ceo fajl verovatno
export const inputTextEditor = function(cell: any, onRendered: any, success: any, cancel: any, editorParams: any) {
    const editor = document.createElement("input");

    editor.setAttribute("type", "text");

    editor.style.padding = "3px";
    editor.style.width = "100%";
    editor.style.boxSizing = "border-box";

    editor.value = cell.getValue() || '';

    onRendered(function(){
		editor.focus();
		editor.style.height = "100%";
    });

    function successFunc(){
		if (!editor.value) {
			success(undefined);
			return;
		}
		success(editor.value);
    }

	editor.addEventListener("change", successFunc);
	editor.addEventListener("blur", successFunc);

	editor.addEventListener("keydown", function(e) {
		switch (e.key) {
			case 'Tab':
				e.stopPropagation();
				break;
			case 'Escape':
				cancel();
				break;
			case 'Enter':
				successFunc();
				break;
			default:
				return;
		}
	});

    return editor;
};
