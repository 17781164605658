import { useCallback } from 'react'
import { DragDropContext, DropResult, Droppable, Draggable } from 'react-beautiful-dnd'
import styles from './reordableColumns.module.scss'

type Props = {
	frozenStartElementIds: string[]
	draggableElementIds: string[]
	frozenEndElementIds: string[]
	onReorder: (elementIds: string[]) => void
	renderItem(id: string): any
	disabled?: boolean
}

export const ReordableColumns = ({ frozenStartElementIds, draggableElementIds, frozenEndElementIds, onReorder, renderItem, disabled }: Props) => {
	const onDragEnd = useCallback(
		(result: DropResult) => {
			const { destination, source, draggableId } = result;

			if (!destination || destination.index === source.index) {
				return;
			}

			const newDraggableElementIds = [...draggableElementIds];
			newDraggableElementIds.splice(source.index, 1);
			newDraggableElementIds.splice(destination.index, 0, draggableId);

			const newColumnOrderIds = [
				...frozenStartElementIds,
				...newDraggableElementIds,
				...frozenEndElementIds,
			];

			onReorder(newColumnOrderIds);
		},
		[draggableElementIds, onReorder, frozenStartElementIds, frozenEndElementIds]
	);

	return (
		<DragDropContext onDragEnd={onDragEnd}>
			<Droppable droppableId='header_row' direction='horizontal'>
				{(provided, snapshot) => (
					<div
						className={`${styles.container} ${snapshot.isDraggingOver ? styles.dragging_over : ''}`}
						ref={provided.innerRef}
						{...provided.droppableProps}
					>
						{draggableElementIds.map((id, index) => (
							<Draggable key={id} draggableId={id} index={index} isDragDisabled={disabled}>
								{(provided, snapshot) => (
									<div
										className={`${snapshot.isDragging ? styles.dragging : ''}`}
										ref={provided.innerRef}
										{...provided.draggableProps}
										{...provided.dragHandleProps}
									>
										{renderItem(id)}
									</div>
								)}
							</Draggable>
						))}
						{provided.placeholder}
					</div>
				)}
			</Droppable>
		</DragDropContext>
	)
}
