import { memo, useCallback, useMemo } from 'react';
import styles from './pagination.module.scss';
import { ArrowLeftNewIcon } from 'components/icons/icons';
import { ArrowRightNewIcon } from 'components/icons/icons';
import { ArrowDoubleLeftNewIcon } from 'components/icons/icons';
import { ArrowDoubleRightNewIcon } from 'components/icons/icons';

type Props = {
	offset: number
	limit: number
	count: number
	onChange: (offset: number) => void
	compact?: boolean
}

const PaginationWithoutMemo = ({ offset, limit, count, onChange, compact }: Props) => {
	const disabledPrev = offset === 0;
	const disabledNext = offset + limit >= count;

	const firstPageCallback = useCallback(
		() => {
			!disabledPrev && onChange(0);
		},
		[disabledPrev, onChange]
	)

	const lastPageCallback = useCallback(
		() => {
			!disabledNext && onChange(Math.floor((count - 1) / limit) * limit);
		},
		[disabledNext, onChange, count, limit]
	)

	const nextPageCallback = useCallback(
		() => {
			!disabledNext && onChange(offset + limit);
		},
		[disabledNext, onChange, offset, limit]
	)

	const prevPageCallback = useCallback(
		() => {
			!disabledPrev && onChange(offset - limit);
		},
		[disabledPrev, onChange, offset, limit]
	)

	const currentPage = useMemo(
		() => Math.ceil((offset + 1) / limit),
		[offset, limit],
	)

	// in compact mode, if there is only one page, do not show pagination
	if (compact && count <= limit) {
		return <></>
	}

	return (
		<div className={styles.container}>
			<div
				className={`${styles.item} ${disabledPrev ? styles.disabled : ''}`}
				onClick={firstPageCallback}
			>
				<ArrowDoubleLeftNewIcon width={14} height={14} />
			</div>
			<div
				className={`${styles.item} ${disabledPrev ? styles.disabled : ''}`}
				onClick={prevPageCallback}
			>
				<ArrowLeftNewIcon width={14} height={14} />
			</div>
			<div
				className={styles.item}
				>{currentPage.toString()}</div>
			<div
				className={`${styles.item} ${disabledNext ? styles.disabled : ''}`}
				onClick={nextPageCallback}
			>
				<ArrowRightNewIcon width={14} height={14} />
			</div>
			<div
				className={`${styles.item} ${disabledNext ? styles.disabled : ''}`}
				onClick={lastPageCallback}
			>
				<ArrowDoubleRightNewIcon width={14} height={14} />
			</div>
		</div>
	)
}

export const Pagination = memo(PaginationWithoutMemo);
