import { MultiSelect } from 'components/Form'
import { useMemo } from 'react'
import { OptionsColumnModel } from '../../models'

type Props = {
	column: OptionsColumnModel<any>
	value: (string | number)[]
	onChange(value: (string | number)[]): void
	onBlur(): void
	loading: boolean
}

export const OptionsEditableCell = ({ column, value, onChange, onBlur, loading }: Props) => {
	const specificPropsMemo = useMemo(
		() => {
			const { items, getItemId, getItemText } = column;

			return {
				items,
				getItemId,
				getItemText
			}
		},
		[column]
	)

	return (
		<MultiSelect
			value={value}
			onChange={onChange}
			onBlur={onBlur}
			{...specificPropsMemo}
			loading={loading}
			size='small'
			focus
		/>
	)
}
