import React, { useEffect, useMemo } from 'react';
import { ExportDataModel, ProjectResponse } from 'services/tenantManagementService';
import { getProjectsGenericAction, exportAction } from '../action';
import { projectsChangedTopic } from './projectsChanged';
import { defaultDefaultProjectVisibleColumns, defaultVisibleColumns, useTableColumnsMemo } from './tableColumns';
import { ColumnContainer } from 'components/Layout';
import { FilterSortPageType, GenericColumnModel, InteractionManager } from 'components/Table';
import { useTableInteractionManager } from 'features/Crud/useTableInteractionManager';
import { propertyOf } from 'utils/propertyOf';
import { prepareTableColumns } from 'features/TableColumns/prepareTableColumns';
import { RemoteTable } from 'components/Table';

type Props = {
	tableId: string
	renderTableButtons(
		visibleColumns: GenericColumnModel[],
		hiddenColumns: GenericColumnModel[],
		filterSortPage: FilterSortPageType,
		interactionManager: InteractionManager,
		memoExportFunction : (exportDataModel: ExportDataModel) => any
	): React.ReactElement
	defaultProjectId?: number
	onSelect(project: ProjectResponse): void
}

export const ProjectsTable = ({ tableId, renderTableButtons, defaultProjectId, onSelect }: Props) => {
	const { interactionManager, columnsOrder, columnsWidth, filterSortPage } = useTableInteractionManager<ProjectResponse>(
		tableId,
		propertyOf<ProjectResponse>('id'),
		false
	);
	const selectedRow = interactionManager.selectedRow;

	const tableColumns = useTableColumnsMemo(defaultProjectId);
	const { visibleColumns, hiddenColumns } = prepareTableColumns(tableColumns, defaultProjectId ? defaultDefaultProjectVisibleColumns : defaultVisibleColumns, columnsOrder, columnsWidth);

	const memoFetchFunction = useMemo(
		() => getProjectsGenericAction,
		[]
	)

	const memoExportFunction = exportAction;

	const tableButtonsContent = useMemo(
		() => renderTableButtons(visibleColumns, hiddenColumns, filterSortPage, interactionManager, memoExportFunction),
		[renderTableButtons, visibleColumns, hiddenColumns, filterSortPage, interactionManager, memoExportFunction]
	)

	useEffect(
		() => {
			onSelect(selectedRow)
		},
		[onSelect, selectedRow]
	)

	return (
		<ColumnContainer margin='medium'>
			{tableButtonsContent}
			<RemoteTable
				columns={visibleColumns}
				filterSortPage={filterSortPage}
				fetchFunction={memoFetchFunction}
				interactionManager={interactionManager}
				subscriptionTopic={projectsChangedTopic}
			/>
		</ColumnContainer>
	)
}
