import { useCallback, useLayoutEffect, useRef, useState } from "react";
import { Body } from "./Body/Body";
import { Header } from "./Header/Header";
import { GenericColumnModel, FilterSortPageType, FilterType, InteractionManager } from "./models";
import styles from './customTable.module.scss'
import { Frozen } from "./Separators/Frozen";
import { Pagination } from "../BaseTable/Pagination/Pagination";
import { Resize } from "./Separators/Resize";
import { Filter } from "./Filter/Filter";
import { columnMinWidth, defaultPaginationLimit } from "./consts";
import { getColumnMaxInitialWidth } from "./helpers";
import { Footer } from "./Footer/Footer";
import { OverlaySpinner } from "components/Spinner";

export const customTableId = 'customTableId';

type Props = {
	columns: GenericColumnModel[]
	rowsData: any[]
	cellEdited?: (rowData: any, columnId: string, value: any) => Promise<void>
	/** Every next refetch so Spinner can be displayed */
	refetching?: boolean

	pagination?: {
		offset?: number // starting index
		limit?: number // max number of rows
		count: number // length of all items
		onChange: (offset: number) => void
	}
	compact?: boolean

	filterSortPage: FilterSortPageType
	disabledReorder: boolean

	/**
	 * - fill: Table will resize the columns and expand each column equally to fill up width of the table. However, if column has set width, it will keep that specific width.
	 * - If fill is not set, fitData will be used by default. Table will resize the columns to fit their data, and ensure that rows take up the full width of the table.
	*/
	fill?: boolean

	interactionManager: InteractionManager
}

export type ColumnWidthMap = { [columnId: string]: number }

export const CustomTable = ({
	columns, rowsData, cellEdited, refetching,
	pagination, compact, filterSortPage, disabledReorder, fill, interactionManager
}: Props) => {
	const containerRef = useRef<HTMLDivElement | null>(null);
	const [columnWidthMap, setColumnWidthMap] = useState<ColumnWidthMap>({});

	const [activeFilterColumn, setActiveFilterColumn] = useState<GenericColumnModel>();

	const calculateColumnWidthsCallback = useCallback(
		() => {
			const container = containerRef.current;

			if (!container) {
				return;
			}

			const maxColumnWidthMap: ColumnWidthMap = {};

			for (let i = 0; i < columns.length; i++) {
				const column = columns[i];
				const columnId = column.id;

				// if width is set, use it directly
				if (column.width) {
					maxColumnWidthMap[columnId] = column.width;
				} else {
					// if width is not set, get all the cells that belong to this column and calculate which cell width is the biggest
					const innerCells = container.querySelectorAll(`[data-columnid="${columnId}"]`);
					const maxInitialWidth = getColumnMaxInitialWidth(column);

					maxColumnWidthMap[columnId] = columnMinWidth;

					for (let j = 0; j < innerCells.length; j++) {
						// calculate cellWidth as content width + cell horizontal padding
						const innerCell = innerCells[j];
						const cell = innerCell.closest('[data-type="cell"]')!;
						const paddingLeft = window.getComputedStyle(cell, null).getPropertyValue('padding-left');
						const paddingRight = window.getComputedStyle(cell, null).getPropertyValue('padding-right');
						let cellWidth = innerCell.getBoundingClientRect().width + parseInt(paddingLeft) + parseInt(paddingRight);

						// if cell is editable it contains small icon that is shown on hover, so we leave space for icon
						if (column.editable) {
							cellWidth += 18; // TODO:code_improvement 18 is change icon width + margin-left, and it shouldn't be hardcoded here
						}

						// if cellWidth is bigger than maxInitialWidth, then calculation is finished and we use maxInitialWidth value as Column width
						if (cellWidth >= maxInitialWidth) {
							maxColumnWidthMap[columnId] = maxInitialWidth;
							break;
						}

						if (cellWidth > maxColumnWidthMap[columnId]) {
							maxColumnWidthMap[columnId] = Math.ceil(cellWidth);
						}
					}
				}
			}

			const tableContainerWidth = container.getBoundingClientRect().width;
			const totalColumnWidth = columns.reduce((total, column) => total + maxColumnWidthMap[column.id], 0);

			// if fill === true and there is more empty space on the right, increase column widths so there is no empty space
			if (fill && totalColumnWidth < tableContainerWidth) {
				// TODO:code_improvements when fill and table is smaller than container,
				// the last column resize line is out of the bounds and horizontal scroll appears,
				// so we add -3 which represents half width of resize handle
				const extraSpace = tableContainerWidth - totalColumnWidth - 3;
				// increase column widths equally, except ones that have width set (GenericColumnModel.width)
				const affectedColumns = columns.filter((column) => !column.width);
				for (const column of affectedColumns) {
					const columnId = column.id;
					maxColumnWidthMap[columnId] = maxColumnWidthMap[columnId] + Math.floor(extraSpace / affectedColumns.length);
				}
			}

			setColumnWidthMap(maxColumnWidthMap);
		},
		[columns, fill]
	)

	useLayoutEffect(
		() => {
			calculateColumnWidthsCallback();
		},
		[rowsData, calculateColumnWidthsCallback]
	)

	const setFiltersCallback = useCallback(
		(newFilters: FilterType[]) => {
			interactionManager.filter?.(newFilters);
			setActiveFilterColumn(undefined);
		},
		[interactionManager]
	)

	const cancelFilterCallback = useCallback(
		() => setActiveFilterColumn(undefined),
		[]
	)

	const onFilterClickCallback = useCallback(
		(columnId: string) => {
			const selectedColumn = columns.find((column) => column.id === columnId);
			setActiveFilterColumn(selectedColumn);
		},
		[columns]
	)

	return (
		<div>
			<Filter
				filters={filterSortPage.filters || []}
				column={activeFilterColumn}
				columns={columns}
				onSave={setFiltersCallback}
				onCancel={cancelFilterCallback}
			/>
			<div id={customTableId} ref={containerRef} className={styles.container}>
				<Header
					columns={columns}
					columnWidthMap={columnWidthMap}
					filterSortPage={filterSortPage}
					onFilter={onFilterClickCallback}
					disabledReorder={disabledReorder}
					interactionManager={interactionManager}
				/>
				<Body
					columns={columns}
					rowsData={rowsData}
					columnWidthMap={columnWidthMap}
					cellEdited={cellEdited}
					interactionManager={interactionManager}
				/>
				{/* TODO:code_improvements treba staviti neki prazan red umesto body i footer i mozda "No data", videti sta je Andrej smislio */}
				{rowsData.length > 0 &&
					<Footer
						columns={columns}
						columnWidthMap={columnWidthMap}
					/>
				}
				<Resize
					columns={columns}
					columnWidthMap={columnWidthMap}
					setColumnWidthMap={setColumnWidthMap}
					interactionManager={interactionManager}
				/>
				<Frozen
					columns={columns}
					columnWidthMap={columnWidthMap}
					containerRef={containerRef}
				/>
			</div>
			{pagination &&
				<Pagination
					offset={pagination.offset || 0}
					limit={pagination.limit || defaultPaginationLimit}
					count={pagination.count}
					onChange={pagination.onChange}
					compact={compact}
				/>
			}
			{refetching &&
				<OverlaySpinner
					size={60}
					useBrandColor
					withBackground
				/>
			}
		</div>
	)
}
