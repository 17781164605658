import { DeleteIcon, LinkIcon, PlusIcon } from 'components/icons/icons'
import { useCallback, useMemo, useState } from 'react'
import styles from './linkCell.module.scss'
import { Input } from 'components/Form'
import { getAbsoluteUrl } from '../LinkCell/helper'

type Props = {
	value: string
	onChange(value: string): void
	onBlur(): void
}

export const LinkEditableCell = ({ value, onChange, onBlur }: Props) => {
	const [isOpen, setIsOpen] = useState(false);
	const [newLink, setNewLink] = useState('');
	const [showLinkInput, setShowLinkInput] = useState(false);

	const openCellDropdownCallback = useCallback(
		() => setIsOpen(true),
		[]
	)

	const closeCellDropdownCallback = useCallback(
		() => setIsOpen(false),
		[]
	)

	const handleOpenLinkCallback = useCallback(
		(link: string) => {
			const url = getAbsoluteUrl(link);
			window.open(url, '_blank');
			closeCellDropdownCallback();
		},
		[closeCellDropdownCallback]
	)

	const handleDeleteCallback = useCallback(
		(uri: string) => {
			const updatedLinks = value.split(';').filter(link => link !== uri).join(';');
			onChange(updatedLinks);
		},
		[value, onChange]
	)

	const handleOnChangeCallback = useCallback(
		() => {
			const updatedLinks = value ? `${value};${newLink}` : newLink;
			onChange(updatedLinks);
			// setNewLink('');
			// setShowLinkInput(false);
		},
		[value, newLink, onChange]
	)

	const handleOnBlurCallback = useCallback(
		() => {
			onBlur();
			handleOnChangeCallback();
		},
		[onBlur, handleOnChangeCallback]
	)

	const linkCount = useMemo(
		() => (value || '').split(';').filter(x => !!x).length,
		[value]
	)

	return (
		<div className={styles.container}>
			<div onClick={openCellDropdownCallback}>
				<LinkIcon width={12} height={12} fill='currentColor' />
				{linkCount > 0 && <span className={styles.link_badge}>{linkCount}</span>}
			</div>
			{isOpen &&
				<div className={styles.dropdown_content} onMouseLeave={closeCellDropdownCallback}>
					{(value || '').split(';').filter(x => !!x).map((link, i) => (
						<div className={styles.link_item} key={i}>
							<div
								className={styles.link}
								onClick={() => handleOpenLinkCallback(link)}
								title={link}
								>
								{link}
							</div>
							<div className={styles.delete_icon}>
								<DeleteIcon width={10} height={10} fill='currentColor' onClick={() => handleDeleteCallback(link)} />
							</div>
						</div>
					))}
					{!showLinkInput ?
						<div className={styles.add_icon} onClick={() => setShowLinkInput(true)}>
							<PlusIcon width={10} height={10} fill='currentColor' />
						</div>
						:
						<Input
							value={newLink}
							onChange={(newValue: string) => setNewLink(newValue)}
							onBlur={handleOnBlurCallback}
							hideMaxLength
							size='small'
							focus
						/>
					}
				</div>
			}
		</div>
	)
}
