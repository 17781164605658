import { convertMeridiemTimeToTicks } from 'utils/dateTimeUtils';
import { MeridiemTimeEnum } from 'utils/MeridiemTimeEnum';

const isNumericChar = (char: string) => /\d/.test(char);

export const sanitizeTimeInput = (value: string) => {
	let sanitizedValue = '--:-- --';
	const filteredValue = value.replace(/[^0-9APMapm]/gi, '');

	let hoursCount = 0;
	let minutesCount = 0;

	for (let i = 0; i < filteredValue.length; i++) {
		const char = filteredValue[i];

		if (sanitizedValue.includes('-')) {
			if (hoursCount < 2 && isNumericChar(char)) {
				sanitizedValue = sanitizedValue.replace('-', char);
				hoursCount++;
			} else if (minutesCount < 2 && isNumericChar(char)) {
				sanitizedValue = sanitizedValue.replace('-', char);
				minutesCount++;
			} else if (hoursCount === 2 && minutesCount === 2 && (char.toUpperCase() === 'A' || char.toUpperCase() === 'P')) {
				sanitizedValue = sanitizedValue.replace(' --', ` ${char.toUpperCase()}M`);
			}
		}

		const [hoursStr, minutesStr] = sanitizedValue.split(':');
		const hours = parseInt(hoursStr);
		if (hoursCount === 2 && (hours < 1 || hours > 12)) {
			sanitizedValue = sanitizedValue.replace(/^[0-9]{2}/, '12');
		}

		const minutes = parseInt(minutesStr);
		if (minutesCount === 2 && (minutes < 0 || minutes > 59)) {
			sanitizedValue = sanitizedValue.replace(/:[0-9]{2}/, ':00');
		}
	}

	const [hoursStr, minutesStr, amOrPm] = sanitizedValue.split(/:|\s/);
	const hours = hoursStr.padStart(2, '0');
	const minutes = minutesStr.padStart(2, '0');

	return `${hours}:${minutes} ${amOrPm}`;
}

export const convertSanitizeTimeToTicks = (sanitizedValue: string) => {
	const timeParts = sanitizedValue.split(' ');

	if (timeParts.length === 2) {
		const [timePart, amPmPart] = timeParts;
		const [hoursStr, minutesStr] = timePart.split(':');

		if (hoursStr && minutesStr && (amPmPart === 'AM' || amPmPart === 'PM')) {
			const hours = parseInt(hoursStr);
			const minutes = parseInt(minutesStr);
			const amOrPm = amPmPart === 'AM' ? MeridiemTimeEnum.AM : MeridiemTimeEnum.PM;

			const ticks = convertMeridiemTimeToTicks(hours, minutes, amOrPm);
			return ticks;
		}
	}

	return undefined;
}

export const increaseHours = (value: string) => {
	let hours = parseInt(value.substring(0, 2)) || 0;
	hours = hours < 12 ? hours + 1 : 1;
	return `${hours.toString().padStart(2, '0')}${value.substring(2)}`;
};

export const decreaseHours = (value: string) => {
	let hours = parseInt(value.substring(0, 2)) || 0;
	hours = hours > 1 ? hours - 1 : 12;
	return `${hours.toString().padStart(2, '0')}${value.substring(2)}`;
};

export const increaseMinutes = (value: string) => {
	let minutes = parseInt(value.substring(3, 5)) || 0;
	minutes = minutes < 59 ? minutes + 1 : 0;
	return `${value.substring(0, 3)}${minutes.toString().padStart(2, '0')}${value.substring(5)}`;
};

export const decreaseMinutes = (value: string) => {
	let minutes = parseInt(value.substring(3, 5)) || 0;
	minutes = minutes > 0 ? minutes - 1 : 59;
	return `${value.substring(0, 3)}${minutes.toString().padStart(2, '0')}${value.substring(5)}`;
};

export const togglePeriod = (value: string) => {
	let currentPeriod = value.substring(6, 8);
	currentPeriod = currentPeriod === 'AM' ? MeridiemTimeEnum.PM : MeridiemTimeEnum.AM;
	return `${value.substring(0, 6)}${currentPeriod}`
}