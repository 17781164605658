import Button from 'components/Button';
import { Form, SelectField } from 'components/Form';
import { SmartContainer, SmartItem } from 'components/SmartContainer/SmartContainer';
import { useCallback, useEffect, useState } from 'react';
import { GenericFilterOperationEnum } from 'services/tenantManagementService';
import { findFilter } from '../../filtersHelper';
import { BooleanColumnModel, FilterType } from 'components/Table';

type Props = {
	column: BooleanColumnModel
	filters: FilterType[]
	onSave: (filters: FilterType[]) => void
	onCancel: () => void
}

type State = {
	selectedValue: 'true' | 'false' | ''
}

const filterOptions = ['true', 'false'];

export const BooleanFilter = ({ column, filters, onSave, onCancel }: Props) => {
	const [values, setValues] = useState<State>({ selectedValue: '' });

	useEffect(
		() => {
			const currentFilter = findFilter(filters, column.id);

			if (currentFilter) {
				setValues({ selectedValue: currentFilter.value });
			} else {
				setValues({ selectedValue: '' });
			}
		},
		[filters, column.id]
	)

	const submitCallback = useCallback(
		async () => {
			let newFilters = [...filters];

			const currentFilter = findFilter(newFilters, column.id);
			if (currentFilter) {
				const index = newFilters.indexOf(currentFilter);
				newFilters.splice(index, 1);
			}

			if (values.selectedValue === 'true' || values.selectedValue === 'false') {
				newFilters.push({
					columnId: column.id,
					operation: GenericFilterOperationEnum.EQUALS,
					value: values.selectedValue === 'true'
				})
			}

			onSave && await onSave(newFilters);
		},
		[filters, column.id, onSave, values]
	)

	const clearCallback = useCallback(
		() => {
			setValues({ selectedValue: '' });
		},
		[]
	)

	return (
		<Form
			values={values}
			onChange={setValues}
			onSubmit={submitCallback}
			onCancel={onCancel}
			submitButtonText='Apply'
			disableUnsavedChangesGuard
			render={() => (
				<SmartContainer>
					<SmartItem>
						<SelectField
							id='selectedValue'
							label='Select option'
							items={filterOptions}
							getItemId={item => item}
							getItemText={item => item}
						/>
					</SmartItem>
				</SmartContainer>
			)}
			renderAdditionalButtons={() => (
				<Button
					text='Clear'
					color='destructive'
					onClick={clearCallback}
				/>
			)}
		/>
	)
}
