import { useCallback, useEffect, useMemo, useState } from "react"
import { GenericColumnModel, InteractionManager } from "../models"
import styles from './resize.module.scss'
import { ColumnWidthMap } from "../CustomTable"
import { columnMinWidth } from "../consts"

type LineProps = {
	columnId: string
	left: number
	active: boolean
	setActive(columnId?: string): void
	resize(columnId: string, dx: number): void
	resizeComplete(columnId: string): void
	resetWidth(columnId): void
}

const Line = ({ columnId, left, active, setActive, resize, resizeComplete, resetWidth }: LineProps) => {
	const [isMouseMoved, setIsMouseMoved] = useState(false);

	const mouseDownCallback = useCallback(
		(e: React.MouseEvent) => {
			e.preventDefault();

			setIsMouseMoved(false);
			setActive(columnId);
		},
		[columnId, setActive]
	);

	const mouseMoveCallback = useCallback(
		(e: MouseEvent) => {
			if (!active) {
				return;
			}

			setIsMouseMoved(true);
			resize(columnId, e.movementX);
		},
		[active, columnId, resize]
	)

	const mouseUpCallback = useCallback(
		(e: MouseEvent) => {
			setActive(undefined);
			if (isMouseMoved) {
				resizeComplete(columnId);
			}
		},
		[columnId, resizeComplete, setActive, isMouseMoved]
	)

	useEffect(
		() => {
			if (active) {
				document.addEventListener('mousemove', mouseMoveCallback);
				document.addEventListener('mouseup', mouseUpCallback);
			}

			return () => {
				document.removeEventListener('mousemove', mouseMoveCallback);
				document.removeEventListener('mouseup', mouseUpCallback);
			}
		},
		[active, mouseMoveCallback, mouseUpCallback]
	)

	const resetWidthCallback = useCallback(
		() => {
			resetWidth(columnId);
		},
		[columnId, resetWidth]
	)

	return (
		<div
			className={`${styles.line} ${active ? styles.active : ''}`}
			style={{ left }}
			onMouseDown={mouseDownCallback}
			onDoubleClick={resetWidthCallback}
		/>
	)
}

type Props = {
	columns: GenericColumnModel[]
	columnWidthMap: ColumnWidthMap
	setColumnWidthMap: React.Dispatch<React.SetStateAction<ColumnWidthMap>>
	interactionManager: InteractionManager
}

export const Resize = ({ columns, columnWidthMap, setColumnWidthMap, interactionManager }: Props) => {
	const [activeColumnId, setActiveColumnId] = useState<string>();

	const resizeCallback = useCallback(
		(columnId: string, dx: number) => {
			setColumnWidthMap((state) => {
				const newValue = { ...state };
				if (newValue[columnId] + dx >= columnMinWidth) {
					newValue[columnId] += dx;
				} else {
					newValue[columnId] = columnMinWidth;
				}
				return newValue;
			});

		},
		[setColumnWidthMap]
	)

	const resizeCompleteCallback = useCallback(
		(columnId: string) => {
			const width = columnWidthMap[columnId];
			interactionManager.resizeColumn?.(columnId, width);
		},
		[columnWidthMap, interactionManager]
	)

	const resetColumnWidthCallback = useCallback(
		(columnId: string) => {
			interactionManager.resizeColumn?.(columnId, undefined);
		},
		[interactionManager]
	)

	const linesContent = useMemo(
		() => {
			const lines: any[] = [];
			let left = 0;

			for (const column of columns) {
				left += columnWidthMap[column.id] || 0;

				// disable resize of frozen columns for now because it behaves strange
				if (column.frozen) {
					continue;
				}

				lines.push(
					<Line
						key={column.id}
						columnId={column.id}
						// TODO:code_improvements -3 is because resize line has 7px width, should be moved to variable and read from here
						left={left - 3}
						active={column.id === activeColumnId}
						setActive={setActiveColumnId}
						resize={resizeCallback}
						resizeComplete={resizeCompleteCallback}
						resetWidth={resetColumnWidthCallback}
					/>
				)
			}

			return lines;
		},
		[columns, columnWidthMap, resetColumnWidthCallback, activeColumnId, resizeCallback, resizeCompleteCallback]
	)

	return (
		<div>
			{linesContent}
		</div>
	)
}
