import { ControlsCommonProps } from "components/Form/fields";
import { useMemo } from "react";
import { SemaphoreItem, SemaphoreItemType } from "./SemaphoreItem";
import styles from './semaphore.module.scss'
import { InfoPopup, POSITION } from "components/Popup";

export type SemaphoreProps = ControlsCommonProps<string | number | boolean> & {
	colors?: SemaphoreItemType[]
	explanation?: string
}

export const Semaphore = ({ colors, value, onChange, disabled, explanation }: SemaphoreProps) => {
	const itemContent = useMemo(
		() => {
			return colors?.map((item) => {
				return (
					<SemaphoreItem
						key={item.id.toString()}
						{...item}
						isSelected={value === item.id}
						onClick={onChange}
						disabled={disabled}
					/>
				)
			})
		},
		[colors, value, onChange, disabled]
	);

	return (
		<div className={styles.wrapper}>
			{itemContent}

			{explanation &&
				<div className={styles.explanation}>
					<InfoPopup
						message={explanation}
						position={POSITION.RIGHT_CENTER}
					/>
				</div>
			}
		</div>
	)
}
