
import { FilterItems } from './FilterItems';
import { Shorten } from 'components/Shorten/Shorten';
import styles from './activeFilter.module.scss'
import { FilterType } from '../../models';
import { DeleteIcon } from 'components/icons/icons';
import { GenericColumnModel } from '../../models';

type Props = {
	filters: FilterType[]
	columns: GenericColumnModel[]
	onClearAll: () => void
}

export const ActiveFilter = ({ filters, columns, onClearAll }: Props) => {
	return (
		<div className={styles.container}>
			<h3 className={styles.header}>Filter</h3>
			<div className={styles.items}>
				<Shorten>
					<FilterItems
						filters={filters}
						columns={columns}
					/>
				</Shorten>
			</div>
			<div className={styles.button} onClick={onClearAll}>
				<DeleteIcon width={8} height={8} fill='currentColor' />
			</div>
		</div>
	)
}
