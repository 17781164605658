import { useCallback, useEffect, useRef } from 'react';
import { MaxLength } from '../../addons';
import styles from './input.module.scss';
import { ControlsCommonProps } from '../../fields';
import { InfoPopup, POSITION } from 'components/Popup';
import { OverlaySpinner } from 'components/Spinner';

export type SelectionType = {
	start: number
	end: number
}

export type InputProps = ControlsCommonProps<string> & {
	type?: string
	placeholder?: string
	maxLength?: number
	hideMaxLength?: boolean
	focus?: boolean
	onFocus?(): void
	selectAllTextOnFocus?: boolean
	explanation?: string
	onKeyDown?(eventKey: string, event: React.KeyboardEvent<HTMLInputElement>): void
	onClick?(event: React.MouseEvent<HTMLInputElement>): void
	size?: 'medium' | 'small'
	loading?: boolean
	selection?: SelectionType
}

export const Input = ({
	value,
	onChange,
	disabled,
	onBlur,
	type = 'text',
	explanation,
	placeholder,
	maxLength = 40,
	hideMaxLength,
	focus,
	onFocus,
	selectAllTextOnFocus,
	onKeyDown,
	onClick,
	size = 'medium',
	loading,
	selection
}: InputProps) => {

	const inputRef = useRef<HTMLInputElement>(null);

	const onChangeCallback = useCallback(
        (e) => onChange && onChange(e.target.value),
        [onChange]
	)

	const onFocusCallback = useCallback(
		() => {
			if (selectAllTextOnFocus) {
				inputRef.current?.select();
			}
			onFocus && onFocus();
		},
		[selectAllTextOnFocus, onFocus]
	)

	// hack: ako je setovan fokus, fokusiraj element
	useEffect(
		() => {
			focus && setTimeout(() => inputRef.current?.focus(), 1)
		},
		[focus]
	)

	useEffect(
		() => {
			if (selection && inputRef.current) {
				inputRef.current.setSelectionRange(selection.start, selection.end);
			}
		},
		[selection]
	)

	const onKeyDownCallback = useCallback(
		(e: React.KeyboardEvent<HTMLInputElement>) => onKeyDown && onKeyDown(e.key, e),
		[onKeyDown]
	)

	const onClickCallback = useCallback(
		(e: React.MouseEvent<HTMLInputElement>) => onClick && onClick(e),
		[onClick]
	)

	return (
		<div className={`${styles.wrapper} ${size === 'small' ? styles.small : ''}`}>
			<div className={styles.container}>
				<input
					className={`${styles.input} ${size === 'small' ? styles.small : ''} ${selection ? styles.transparent_cursor : ''}`}
					ref={inputRef}
					type={type}
					value={value || ''}
					onChange={onChangeCallback}
					onFocus={onFocusCallback}
					onBlur={onBlur}
					onKeyDown={onKeyDownCallback}
					onClick={onClickCallback}
					placeholder={placeholder}
					autoFocus={focus}
					disabled={disabled || loading}
					maxLength={hideMaxLength ? undefined : maxLength}
				/>
				{/* max length */}
				{!hideMaxLength && maxLength &&
					<div className={styles.max_length}>
						<MaxLength
							length={value ? value.length : 0}
							maxLength={maxLength}
						/>
					</div>
				}
			</div>
			{/* explanation */}
			{explanation &&
				<div className={styles.explanation}>
					<InfoPopup
						message={explanation}
						position={POSITION.TOP_CENTER}
					/>
				</div>
			}
			{/* loading */}
			{loading && <OverlaySpinner size={20} useBrandColor />}
		</div>
	)
}
