const PADD_ID_LENGTH = 8;

export enum EntityPrefixEnum {
	PROJECT = 'P',
	TICKET = 'T',
	REPORT = 'PR',
	CHANGE_REQUEST = 'CR',
	BUSINESS_PARTNER = 'BP',
	RISK = 'R',
	SURVEY = 'SU',
	TRAVEL_REQUEST = 'TR',
	TRAVEL_EXPENSE = 'TE',
	TIME_TRAVEL_EXPENSE = 'E',
	VACATION_REQUEST = 'VR',
	REMINDER = 'RM',
	STATUS_REPORT = 'SR',
	TASK = 'TS',
	MEETING = 'M'
}

export const getFormatedId = (prefix: EntityPrefixEnum | undefined, id: number | undefined) => {
	if (!id || !prefix) {
		return '';
	}

	const result = prefix + id.toString().padStart(PADD_ID_LENGTH, '0');
	return result;
};

export const getUnformattedId = (prefix: EntityPrefixEnum | undefined, formattedId: string) => {
	if (!prefix) {
		return undefined;
	}

	let id = formattedId;

	if (id.startsWith(prefix)) {
		id = id.substring(prefix.length);
	} else {
		return undefined;
	}

	if (id.length === PADD_ID_LENGTH) {
		// remove padded '0'
		while (id.charAt(0) === '0') {
			id = id.substring(1);
		}
	} else {
		return undefined;
	}

	return parseInt(id);
}

interface Identify {
	id: number
}

class ItemsMap {
	[key: string]: any
}

export function createDelta<TResponse extends Identify>(
	initialItems: TResponse[],
	items: TResponse[],
	InsertClass: (new (obj: any) => any),
	UpdateClass: (new (obj: any) => any),
	DeltaClass: (new () => any),
	DeleteClass?: (new () => any)
) {
	// create maps
	const initialItemMap: ItemsMap = initialItems.reduce((currentMap, item) => {
		return { ...currentMap, [item.id!.toString()]: item };
	}, {})

	const itemMap: ItemsMap = items.reduce((currentMap, item) => {
		return { ...currentMap, [item.id!.toString()]: item };
	}, {})

	const result = new DeltaClass();

	Object.keys(itemMap).forEach((id, index) => {

		const currentItem = itemMap[id];
		const initialItem = initialItemMap[id];

		// insert
		if (!initialItem) {
			const insertItem = new InsertClass(currentItem);
			if (!result.insert) result.insert = [];
			result.insert.push(insertItem);
			return;
		}

		// update
		if (initialItem !== currentItem) {
			const updateItem = new UpdateClass(currentItem);
			if (!result.update) result.update = [];
			result.update.push(updateItem);
		}
	});

	// delete
	Object.keys(initialItemMap).forEach(id => {
		if (!itemMap[id]) {
			if (!result.delete) result.delete = [];
			if (DeleteClass) {
				const deleteObject = new DeleteClass();
				deleteObject.id = parseInt(id);
				result.delete.push(deleteObject);
			} else {
				result.delete.push(parseInt(id));
			}

		}
	});

	return result;
}

// Maybe there
export const unpackDelta = (deltaErrors: any, delta: any, items: Array<any>, initialItems: Array<any>) => {
	const tableErrors: any[] = []

	// create maps
	const initialItemMap: ItemsMap = initialItems.reduce((currentMap, item, index) => {
		return { ...currentMap, [item.id!.toString()]: {...item, index }};
	}, {})

	const itemMap: ItemsMap = items.reduce((currentMap, item, index) => {
		return { ...currentMap, [item.id!.toString()]: {...item, index }};
	}, {})

	// if there are invalid deletes, items are returned to list
	const newItems = [...items];
	// initial indexes of successfully deleted items
	const deletedIndexes: number[] = [];
	// initial indexes of deleted items which are failed
	const returnedIndexes: number[] = [];

	// fill deletedIndexes
	if (delta.delete) {
		delta.delete.forEach((deleteItem: any) => {
			const deleteId = typeof deleteItem === 'number' ? deleteItem: deleteItem.id;
			const deletedIndex = initialItemMap[deleteId].index
			deletedIndexes.push(deletedIndex);
		});
	}

	// fill returnedIndexes, remove items from deletedIndexes
	if (deltaErrors.delete) {
		deltaErrors.delete.forEach((deleteError: any, index: number) => {
			const deleteItem = delta.delete[index];
			const deleteId = typeof deleteItem === 'number' ? deleteItem: deleteItem.id;
			const deletedIndex = initialItemMap[deleteId].index
			returnedIndexes.push(deletedIndex);
			deletedIndexes.splice(deletedIndexes.indexOf(deletedIndex), 1);
		});
	}

	// delete errors can be only global errors
	// set global errors for failed delete rows and return them to newItems
	if (deltaErrors.delete) {
		deltaErrors.delete.forEach((deleteError: any, index: number) => {
			const deleteItem = delta.delete[index];
			const deleteId = typeof deleteItem === 'number' ? deleteItem: deleteItem.id;
			const deletedIndex = initialItemMap[deleteId].index

			const numOfDeletedIndexesBefore = deletedIndexes.reduce(
				(num: number, currentIndex: number) => {
					if (currentIndex < deletedIndex) {
						num++;
					}
					return num;
				},
				0
			)

			const tableIndex = deletedIndex - numOfDeletedIndexesBefore;
			tableErrors[tableIndex] = {
				'': deleteError
			}

			initialItems.forEach((initialItem: any) => {
				if (initialItem.id === deleteId) {
					newItems.splice(tableIndex, 0, initialItem);
					return;
				}
			});

		});
	}

	// set errors for failed inserts
	// calculate row index by returned indexes
	if (deltaErrors.insert) {
		deltaErrors.insert.forEach((insertError: any, index: number) => {
			const insertItem = delta.insert[index];
			let tableIndex = itemMap[insertItem.id].index;

			const numOfReturnedIndexesBefore = returnedIndexes.reduce(
				(num: number, currentIndex: number) => {
					if (currentIndex < tableIndex) {
						num++;
					}
					return num;
				},
				0
			)

			tableIndex -= numOfReturnedIndexesBefore;

			// is global error for that row
			if (typeof insertError === 'string'){
				tableErrors[tableIndex] = {};
				tableErrors[tableIndex][''] = insertError;
			} else {
				Object.keys(insertError).forEach((key: string) => {
					if (!tableErrors[tableIndex]) {
						tableErrors[tableIndex] = {}
					}
					tableErrors[tableIndex][key] = insertError[key];
				});
			}
		});
	}

	// set errors for failed updates
	// calculate row index by returned indexes
	if (deltaErrors.update) {
		deltaErrors.update.forEach((updateError: any, index: number) => {
			const updateItem = delta.update[index];
			let tableIndex = itemMap[updateItem.id].index;
			const initialIndexIndex = initialItemMap[updateItem.id].index;

			const numOfReturnedIndexesBefore = returnedIndexes.reduce(
				(num: number, currentIndex: number) => {
					if (currentIndex < initialIndexIndex) {
						num++;
					}
					return num;
				},
				0
			)

			tableIndex -= numOfReturnedIndexesBefore;

			// is global error for that row
			if (typeof updateError === 'string'){
				tableErrors[tableIndex] = {};
				tableErrors[tableIndex][''] = updateError;
			} else {
				Object.keys(updateError).forEach((key: string) => {
					if (!tableErrors[tableIndex]) {
						tableErrors[tableIndex] = {}
					}
					tableErrors[tableIndex][key] = updateError[key];
				});
			}
		});
	}

	return [tableErrors, newItems];

}

// next methods are used primarily because of shallow compare, because reference is always the same

export const noop = () => {
	return undefined as any
}

export const emptyArray = []

export const emptyObject = {}
