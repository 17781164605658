import { Checkbox } from 'components/Form'

type Props = {
	value: boolean
	onChange(value: boolean): void
	onBlur(): void
	loading: boolean
}

export const BooleanEditableCell = ({ value, onChange, onBlur, loading }: Props) => {
	return (
		<Checkbox
			value={value}
			onChange={onChange}
			onBlur={onBlur}
			loading={loading}
			size='small'
		/>
	)
}
