import { useCallback, useEffect, useLayoutEffect, useMemo, useState } from "react"
import { GenericColumnModel } from "../models"
import styles from './frozen.module.scss'
import { ColumnWidthMap } from "../CustomTable"

type Props = {
	columns: GenericColumnModel[]
	columnWidthMap: ColumnWidthMap
	containerRef: React.MutableRefObject<HTMLDivElement | null>
}

export const Frozen = ({ columns, columnWidthMap, containerRef }: Props) => {
	const [containerClientWidth, setContainerClientWidth] = useState(containerRef.current?.clientWidth || 0);
	const [scrollLeft, setScrollLeft] = useState(containerRef.current?.scrollLeft || 0);

	const { leftColumnIds, rightColumnIds } = useMemo(
		() => {
			let leftIds: string[] = [];
			let rightIds: string[] = [];
			for (let i = 0; i < columns.length; i++) {
				const column = columns[i];
				if (column.frozen === 'start') {
					leftIds.push(column.id);
				}
				if (column.frozen === 'end') {
					rightIds.push(column.id);
				}
			}

			return {
				leftColumnIds: leftIds,
				rightColumnIds: rightIds
			}
		},
		[columns]
	)

	const { leftFrozenPosition, rightFrozenPosition } = useMemo(
		() => {
			let leftPosition = 0;
			if (leftColumnIds) {
				for (const columnId of leftColumnIds) {
					leftPosition += columnWidthMap[columnId] || 0;
				}
			}

			let rightPosition = 0;
			if (rightColumnIds) {
				for (const columnId of rightColumnIds) {
					rightPosition += columnWidthMap[columnId] || 0;
				}
			}

			return {
				leftFrozenPosition: leftPosition,
				rightFrozenPosition: rightPosition
			}
		},
		[leftColumnIds, rightColumnIds, columnWidthMap]
	)

	const updateFrozenCellsPositionCallback = useCallback(
		() => {
			const container = containerRef.current;
			if (!container) {
				return;
			}

			let left = 0;
			for (let i = 0; i < columns.length; i++) {
				const column = columns[i];
				if (column.frozen === 'start') {
					const innerCells = container.querySelectorAll(`[data-columnid="${column.id}"]`);
					for (let j = 0; j < innerCells.length; j++) {
						let innerCell = innerCells[j];
						const cell = innerCell.closest('[data-type="cell"]')!;
						(cell as HTMLElement).style.left = `${left}px`;
					}
				}

				left += columnWidthMap[column.id];
			}

			let right = 0;
			for (let i = columns.length - 1; i > 0; i--) {
				const column = columns[i];
				if (column.frozen === 'end') {
					const innerCells = container.querySelectorAll(`[data-columnid="${column.id}"]`);
					for (let j = 0; j < innerCells.length; j++) {
						let innerCell = innerCells[j];
						const cell = innerCell.closest('[data-type="cell"]')!;
						(cell as HTMLElement).style.right = `${right}px`;
					}
				}

				right += columnWidthMap[column.id];
			}
		},
		[columns, columnWidthMap, containerRef]
	)

	useLayoutEffect(
		() => {
			updateFrozenCellsPositionCallback();
		},
		[updateFrozenCellsPositionCallback]
	)

	const resizeCallback = useCallback(
		() => {
			setContainerClientWidth(containerRef.current?.clientWidth || 0);
		},
		[containerRef]
	)

	const scrollCallback = useCallback(
		() => {
			setScrollLeft(containerRef.current?.scrollLeft || 0);
		},
		[containerRef]
	)

	// while scrolling we need to update frozen line positions
	useEffect(
		() => {
			const container = containerRef.current;
			if (container && (leftFrozenPosition || rightFrozenPosition)) {
				container?.addEventListener('scroll', scrollCallback);
			}

			return () => {
				container?.removeEventListener('scroll', scrollCallback);
			}
		},
		[leftFrozenPosition, rightFrozenPosition, containerRef, scrollCallback]
	)

	// initially right frozen line is not drawn as container probably has width 0
	// additionaly we here also handle when window size is changed
	useEffect(
		() => {
			let resizeObserver: ResizeObserver | undefined;

			const container = containerRef.current;
			if (container && (leftFrozenPosition || rightFrozenPosition)) {
				resizeObserver = new ResizeObserver((entries) => {
					if (entries.length === 1) {
						setContainerClientWidth(entries[0].contentRect.width);
					}
				});
				resizeObserver.observe(containerRef.current!);
			}

			return () => {
				resizeObserver?.disconnect();
			}
		},
		[leftFrozenPosition, rightFrozenPosition, containerRef, resizeCallback]
	)

	return (
		<>
			{leftFrozenPosition !== 0 && <div className={styles.line} style={{ left: scrollLeft + leftFrozenPosition }} />}
			{rightFrozenPosition !== 0 && <div className={styles.line} style={{ left: containerClientWidth + scrollLeft - rightFrozenPosition }} />}
		</>
	)
}
