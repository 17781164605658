import { memo, useCallback, useMemo } from 'react'
import styles from './footer.module.scss'
import { Cell } from './Cell'
import { GenericColumnModel } from '../models'
import { ColumnWidthMap } from '../CustomTable'

type Props = {
	columns: GenericColumnModel[]
	columnWidthMap: ColumnWidthMap
}

const RowWithoutMemo = ({ columns, columnWidthMap }: Props) => {
	const columnsMemo = useMemo(
		() => columns.filter(column => column.frozen !== 'end'),
		[columns]
	)

	const frozenRightColumns = useMemo(
		() => columns.filter(column => column.frozen === 'end'),
		[columns]
	)

	const cellsContent = useCallback(
		(newColumns: GenericColumnModel[]) => {
			return newColumns.map((column) => {
				return (
					<Cell
						column={column}
						key={column.id}
						columnWidth={columnWidthMap[column.id]}
					/>
				)
			})
		},
		[columnWidthMap]
	)

	return (
		<div className={styles.row_wrapper}>
			<div className={styles.row}>
				{columnsMemo.length > 0 && (
					frozenRightColumns.length > 0 ?
						<div className={styles.cells_wrapper}>
							{cellsContent(columnsMemo)}
						</div>
						:
						cellsContent(columnsMemo)
				)}
				{frozenRightColumns.length > 0 &&
					cellsContent(frozenRightColumns)
				}
			</div>
		</div>
	)
}

export const Row = memo(RowWithoutMemo);
