export type ElementType = {
	id: string
	content: string
	frozen?: 'start' | 'end'
}

export class ColumnType {
	id: string = '';
	title: string = '';
	elementIds: string[] = [];

	constructor(column?: ColumnType) {
		if (column) {
			this.id = column.id;
			this.title = column.title;
			// very important to clone, watch out when changing this
			this.elementIds = [...column.elementIds];
		}
	}
}

export type ElementMapType = {
	[key: string]: ElementType
}

export type ColumnMapType = {
	[key: string]: ColumnType
}

export type MultiListType = {
	elementMap: ElementMapType
	columnMap: ColumnMapType
	columnOrder: string[]
}
