import { useCallback, useMemo } from 'react'
import styles from './header.module.scss'
import { GenericColumnModel, FilterSortPageType, InteractionManager } from '../models'
import { Cell } from './Cell'
import { ColumnWidthMap } from '../CustomTable'
import { findFilter } from '../Filter/filtersHelper'
import { ReordableColumns } from 'components/DragNDrop'
import { noop } from 'utils/commonHelper'

type Props = {
	columns: GenericColumnModel[]
	columnWidthMap: ColumnWidthMap
	filterSortPage: FilterSortPageType
	onFilter: (columnId: string) => void
	disabledReorder: boolean
	interactionManager: InteractionManager
}

export const Header = ({ columns, columnWidthMap, filterSortPage, onFilter, disabledReorder, interactionManager }: Props) => {
	const frozenStartColumnIdsMemo = useMemo(
		() => columns.filter(column => column.frozen === 'start').map(x => x.id),
		[columns]
	)

	const draggableColumnIdsMemo = useMemo(
		() => columns.filter(column => !column.frozen).map(x => x.id),
		[columns]
	)

	const frozenEndColumnIdsMemo = useMemo(
		() => columns.filter(column => column.frozen === 'end').map(x => x.id),
		[columns]
	)

	const cellProps = useCallback(
		(column: GenericColumnModel) => {
			return {
				...column,
				sort: filterSortPage.sort,
				setSort: interactionManager.sort,
				hasFilter: !column.disableFilter,
				isFilterActive: findFilter(filterSortPage.filters, column.id) !== undefined,
				onFilter: onFilter,
				columnWidth: columnWidthMap[column.id],
				frozen: !!column.frozen
			}
		},
		[columnWidthMap, filterSortPage, onFilter, interactionManager]
	)

	const renderCellsContent = useCallback(
		(id: string) => {
			const column = columns.find(col => col.id === id);

			if (!column) {
				return;
			}

			return <Cell key={column.id} {...cellProps(column)} />
		},
		[columns, cellProps]
	)

	return (
		<div className={styles.container}>
			<div className={styles.row}>
				{frozenStartColumnIdsMemo.length > 0 && frozenStartColumnIdsMemo.map(id => renderCellsContent(id))}
				<div className={styles.cells_wrapper}>
					<ReordableColumns
						frozenStartElementIds={frozenStartColumnIdsMemo}
						draggableElementIds={draggableColumnIdsMemo}
						frozenEndElementIds={frozenEndColumnIdsMemo}
						onReorder={interactionManager.reorderColumn || noop}
						renderItem={renderCellsContent}
						disabled={disabledReorder}
					/>
				</div>
				{frozenEndColumnIdsMemo.length > 0 && frozenEndColumnIdsMemo.map(id => renderCellsContent(id))}
			</div>
		</div>
	)
}
