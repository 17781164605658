import { GenericFilterModel, GenericFilterModelCollection, GenericSortModel } from "services/tenantManagementService";
import { FilterSortPageType } from "../CustomTable";
import { capitalizeFirstLetter } from "utils/stringUtil";
import { BackendColumnFields, BackendColumnFieldsMap } from "features/TableColumns/prepareExportColumns";

// apply default values to backend filter fields if they are not defined
const applyDefaults = (columnId: string, specificBackendColumnFields: BackendColumnFields | undefined) => {
	const backendFields = { ...specificBackendColumnFields };

	// now lets add some default values where needed

	if (backendFields.dbFilterFieldPath === undefined) {
		backendFields.dbFilterFieldPath = capitalizeFirstLetter(columnId);
	}

	return backendFields;
}

export const prepareFiltersModel = (
	filterSortPage: FilterSortPageType,
	specificBackendColumnFieldsMap: BackendColumnFieldsMap | undefined
): GenericFilterModelCollection => {
	const genericFilter = new GenericFilterModelCollection();
	genericFilter.offset = filterSortPage.offset;
	genericFilter.limit = filterSortPage.limit;

	const sortColumnId = filterSortPage.sort?.columnId
	if (sortColumnId) {
		const backendFields = applyDefaults(sortColumnId, specificBackendColumnFieldsMap?.[sortColumnId]);

		genericFilter.sorts = [
			new GenericSortModel({
				property: backendFields.dbFilterFieldPath,
				isAsc: filterSortPage.sort?.isAsc || false
			})
		]
	}

	const filters = [...(filterSortPage.filters || []), ...(filterSortPage.additionalFilters || [])]
	if (filters) {
		genericFilter.filters = [];
		for (let item of filters) {
			let filterModel = new GenericFilterModel();

			if (item.columnId) {
				const backendFields = applyDefaults(item.columnId, specificBackendColumnFieldsMap?.[item.columnId]);
				filterModel = new GenericFilterModel({
					property: backendFields.dbFilterFieldPath,
					value: item.value,
					operation: item.operation
				})
			}

			if (item.orFilters) {
				filterModel = new GenericFilterModel({
					orFilters: item.orFilters?.map((orFilter) => {
						if (!orFilter.columnId) {
							return new GenericFilterModel();
						}

						const backendFields = applyDefaults(orFilter.columnId, specificBackendColumnFieldsMap?.[orFilter.columnId]);

						return new GenericFilterModel({
							property: backendFields.dbFilterFieldPath,
							value: orFilter.value,
							operation: orFilter.operation
						})
					})
				})
			}

			genericFilter.filters.push(filterModel);
		}
	}

	// this is to specific and will be added directly in action where needed
    // genericFilter.includes?: string[] | undefined;

	return genericFilter;
}
