import { RowContainer } from 'components/Layout';
import { useMemo } from 'react';
import { formatDate, formatTime } from 'utils/dateTimeUtils';
import styles from './filterItem.module.scss';
import { formatFilterOperation } from './filterItemHelper';
import { getFormatedId } from 'utils/commonHelper';
import { MapItem, convertToAbsoluteName, getSelectedItem } from 'components/Form/controls/MapPicker/MapPicker/helpers';
import { BooleanColumnModel, CurrencyColumnModel, DateColumnModel, DurationColumnModel, FilterType, FormattedReferenceColumnModel, GenericColumnModel, MapOptionColumnModel, NumberColumnModel, OptionColumnModel, OptionsColumnModel, StringColumnModel, TimeColumnModel } from '../../models';

type Props = {
	filter: FilterType
	columns: GenericColumnModel[]
}

const FilterItem = ({ filter, columns }: Props) => {
	const filterItemMemo = useMemo(
		() => {
			const column = columns.find((column) => column.id === filter.columnId);
			if (!column) {
				return {};
			}

			let value = '';

			if (column instanceof OptionColumnModel || column instanceof OptionsColumnModel) {
				const { items, getItemId, getItemText } = column;
				for (const option of items) {
					const optionId = getItemId && getItemId(option);
					if (optionId === filter.value) {
						value = getItemText ? getItemText(option) : filter.value;
						break;
					}
				}
			} else if (column instanceof MapOptionColumnModel) {
				const options = column.items as MapItem[] || [];
				const selectedOption = getSelectedItem(filter.value, options);
				if (selectedOption) {
					value = convertToAbsoluteName(selectedOption, options);
				}
			} else if (column instanceof DateColumnModel) {
				value = formatDate(filter.value);
			} else if (column instanceof TimeColumnModel) {
				value = formatTime(filter.value);
			} else if (column instanceof FormattedReferenceColumnModel) {
				const { entityPrefix } = column;
				value = entityPrefix ? getFormatedId(entityPrefix, filter.value) : filter.value;
			} else if (column instanceof BooleanColumnModel) {
				value = String(filter.value)
			} else if (column instanceof NumberColumnModel || column instanceof CurrencyColumnModel || column instanceof StringColumnModel || column instanceof DurationColumnModel) {
				value = filter.value;
			} else {
				value = filter.value;
			}
			return {
				title: column.title,
				value
			}
		},
		[columns, filter]
	)

	return (
		<span className={styles.item}>
			<RowContainer margin='xsmall' wrap='nowrap'>
				<span>{filterItemMemo.title}</span>
				<span className={styles.operation}>{formatFilterOperation(filter.operation)}</span>
				<span>{filterItemMemo.value}</span>
				{/* da li dodati delete, tipa na hover da se pojavi neki 'X' */}
				{/* <span className={styles.delete_button} onClick={deleteCallback}>X</span> */}
			</RowContainer>
		</span>
	)

}

export default FilterItem;
