import { RowContainer } from 'components/Layout';
import { useCallback, useState } from 'react';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { Column } from './Column';
import { MultiListType, ColumnType } from './models'

type Props = {
	onDragEnd: (multiList: MultiListType) => void
	initialMultiList: MultiListType
}

export const MultiList = ({ onDragEnd, initialMultiList }: Props) => {
	const [currentData, setCurrentData] = useState<MultiListType>(initialMultiList);

	const handleDragEnd = useCallback(
		(result: DropResult) => {
			const { destination, source, draggableId } = result;

			if (!destination) {
				return;
			}

			if (
				destination.droppableId === source.droppableId &&
				destination.index === source.index
			) {
				return;
			}

			const startColumn = currentData.columnMap[source.droppableId];
			const finishColumn = currentData.columnMap[destination.droppableId];

			if (startColumn === finishColumn) {
				const newColumn = new ColumnType(startColumn);
				newColumn.elementIds.splice(source.index, 1);
				newColumn.elementIds.splice(destination.index, 0, draggableId);

				const newCurrentData = {
					...currentData,
					columnMap: { ...currentData.columnMap, [newColumn.id]: newColumn }
				}

				setCurrentData(newCurrentData);
				onDragEnd(newCurrentData);
				return;
			}

			const newStartColumn = new ColumnType(startColumn);
			newStartColumn.elementIds.splice(source.index, 1);

			const newFinishColumn = new ColumnType(finishColumn);
			newFinishColumn.elementIds.splice(destination.index, 0, draggableId);

			const newCurrentData = {
				...currentData,
				columnMap: {
					...currentData.columnMap,
					[newStartColumn.id]: newStartColumn,
					[newFinishColumn.id]: newFinishColumn,
				},
			};

			setCurrentData(newCurrentData);
			onDragEnd(newCurrentData);
		},
		[currentData, onDragEnd]
	)

	return (
		<DragDropContext onDragEnd={handleDragEnd}>
			<RowContainer flex={1} margin='large'>
				{currentData.columnOrder.map((columnId) => {
					const column = currentData.columnMap[columnId];
					const elements = column.elementIds.map(
						(elementId) => currentData.elementMap[elementId]
					)

					return <Column key={column.id} {...column} elements={elements} />
				})}
			</RowContainer>
		</DragDropContext>
	)
}
