import { ColumnDefinition, RowComponent, TabulatorFull as Tabulator} from 'tabulator-tables';
import { ReactTabulator } from 'react-tabulator'
import { ColumnContainer } from 'components/Layout';
import ErrorBoundary from 'features/ErrorBoundary';
import { Pagination } from './Pagination/Pagination';
import Filter from './Filter/Filter';
import { forwardRef, useCallback, useEffect, useMemo, useState } from 'react';
import { FilterModel } from './Filter/Filter';
import { noop } from 'utils/commonHelper';
import styles from './baseTable.module.scss';
import { GenericSortModel } from 'services/tenantManagementService';
import { defineCustomTableCells } from '../helpers/customTableCells';
import { BaseColumnModel } from './BaseColumnModel';
import 'tabulator-tables/dist/css/tabulator.min.css'
import 'tabulator-tables/dist/css/tabulator_bootstrap3.min.css';
import './tabulator-override.scss';
import WithRefetch from 'features/Fetch/WithRefetch';
import { useFallbackRef } from 'utils/refUtils';
import { convertBaseColumnHelper } from './baseColumnConvertUtils';

defineCustomTableCells()

export const defaultPaginationSize = 20;

const defaultOptions = {
	layout: 'fitDataFill',
	movableColumns: true,
	selectable: 1,
	tooltipsHeader: true,
	tooltips: true
}

const columnDefaults: ColumnDefinition = {
	title: '', // quick fix, TS complains
}

type Props = {
	fetchFunction: () => Promise<any>
	isLoading: boolean
	columns: BaseColumnModel[]
	rowsData: any[]
	pagination?: {
		offset?: number // starting index
		limit?: number // max number of rows
		count: number // length of all items
		onChange: (offset: number) => void
	}

	filters: FilterModel[]
	setFilters: (filters: FilterModel[]) => void
	sort: GenericSortModel
	setSort: (dbFilterFieldPath: string) => void

	reorderColumns?: (newColumns: string[]) => void // when columns are reordered, to notify parent if he wants to store new order
	rowSelectionChanged?: (data: any[], selectedRows: RowComponent[]) => void

	options?: any
	validationFailed?: (cell: any) => void
	cellEditCancelled?: (cell: any) => void
	cellEdited?: (cell: any) => void
	rowDoubleClick?: (rowData: any) => void
	groupClick?: (e: any, group: any) => void

	compact?: boolean
	withoutPaddingBottom?: boolean // fix for now
}

export const BaseTable = forwardRef((props: Props, ref: React.ForwardedRef<Tabulator>) => {
	const {
		fetchFunction, isLoading,
		columns, rowsData, pagination, filters, setFilters, sort, setSort, reorderColumns, rowSelectionChanged,
		options, validationFailed, cellEditCancelled, cellEdited, rowDoubleClick, groupClick,
		compact, withoutPaddingBottom
	} = props;

	const tabulatorRef = useFallbackRef(ref);

	const [isInitialized, setIsInitialized] = useState(false);

	const [activeFilterColumn, setActiveFilterColumn] = useState<BaseColumnModel>();

	const setFiltersCallback = useCallback(
		(newFilters: FilterModel[]) => {
			setFilters(newFilters);
			setActiveFilterColumn(undefined);
		},
		[setFilters]
	)

	const cancelFilterCallback = useCallback(
		() => setActiveFilterColumn(undefined),
		[]
	)

	const onFilterClickCallback = useCallback(
		(field: string) => {
			const selectedColumn = columns.find((column) => column.field === field);
			setActiveFilterColumn(selectedColumn);
		},
		[columns]
	)

	// sort works by only one column
	const onSortClickCallback = useCallback(
		(field: string) => {
			const selectedColumn = columns.find((column) => column.field === field)!;
			setSort(selectedColumn.dbFilterFieldPath!);
		},
		[setSort, columns]
	)

	const rowDblClick = useCallback(
		(e: any, row: any) => {
			if (!rowDoubleClick || !row?._row?.data) {
				return
			}

			e.preventDefault()
			e.stopPropagation()

			rowDoubleClick(row._row.data)
		},
		[rowDoubleClick],
	)

	const columnsConverted = useMemo(
		() => {
			return columns.map((column) => convertBaseColumnHelper(column, sort, filters, onFilterClickCallback, onSortClickCallback));
		},
		[columns, filters, sort, onFilterClickCallback, onSortClickCallback]
	)

	useEffect(
		() => {
			if (isInitialized) {
				tabulatorRef.current?.setColumns(columnsConverted);
			}
		},
		[isInitialized, columnsConverted, tabulatorRef]
	)

	useEffect(
		() => {
			if (isInitialized) {
				tabulatorRef.current?.setData(rowsData);
			}
		},
		[isInitialized, rowsData, tabulatorRef]
	)

	useEffect(
		() => {
			const current = tabulatorRef.current;
			if (isInitialized) {
				current?.on('cellEdited', cellEdited);

				return () => {
					current?.off('cellEdited', cellEdited);
				}
			}
		},
		[isInitialized, cellEdited, tabulatorRef]
	)

	const handleColumnMove = useCallback(
		(column: any, tabulatorColumns: any[]) => {
			if (reorderColumns) {
				const newColumns: BaseColumnModel[] = tabulatorColumns.map((col) => col.getDefinition());
				const fields = newColumns.map((column) => column.field!);
				reorderColumns(fields);
			}
		},
		[reorderColumns]
	)

	const customOptions = useMemo(
		() => {
			return {
				...defaultOptions,
				...options
			}
		},
		[options]
	)

	// after render is finished, we go thru all cells and check if cell is editable at the moment and applies different CSS style
	const renderCompleteCallback = useCallback(
		() => {
			// setIsInitialized(true); // didn't find better event to check if tabulator is initialized

			if (!tabulatorRef.current) {
				return;
			}

			let isTableEditable = false;

			const rows = tabulatorRef.current.getRows();
			for (const row of rows) {
				for (const cell of row.getCells()) {
					const definition = cell.getColumn().getDefinition();
					const cellComponent = cell;

					if (definition.editor) {
						let isEditableByDefinition = true;
						if (typeof definition.editable === 'function') {
							isEditableByDefinition = definition.editable(cellComponent);
						} else if (typeof definition.editable === 'boolean') {
							isEditableByDefinition = definition.editable;
						}

						if (isEditableByDefinition) {
							const color = 'var(--field-color)';
							const background = 'var(--field-background)';

							const container = cellComponent.getElement();
							container.style.color = color;
							container.style.backgroundColor = background;
						}

						isTableEditable = true;
					}
				}
			}

			if (isTableEditable) {
				tabulatorRef.current?.validate();
			}
		},
		[tabulatorRef]
	)

	return (
		<ErrorBoundary location='BaseTable'>
			<WithRefetch fetchFunction={fetchFunction} isLoading={isLoading}>
				<div className={!withoutPaddingBottom ? styles.container : ''}>
					<ColumnContainer margin='small'>
						<Filter
							filters={filters || []}
							column={activeFilterColumn}
							columns={columns}
							onSave={setFiltersCallback}
							onCancel={cancelFilterCallback}
						/>
						<ReactTabulator
							onRef={(ref) => (tabulatorRef.current = ref.current)}
							columnDefaults={columnDefaults}
							columns={columnsConverted}
							events={{
								tableBuilt: () => setIsInitialized(true),
								rowSelectionChanged: rowSelectionChanged || noop,
								renderComplete: renderCompleteCallback,
								cellEdited: cellEdited || noop, // this way changes of hook dependencies are ignored, so moved to on/off. If needed, move other events to on/off also.
								validationFailed: validationFailed || noop,
								cellEditCancelled: cellEditCancelled || noop,
								columnMoved: handleColumnMove || noop,
								rowDblClick: rowDblClick,
								groupClick: groupClick
							}}
							options={customOptions}
						/>
						{pagination &&
							<Pagination
								offset={pagination.offset || 0}
								limit={pagination.limit || defaultPaginationSize}
								count={pagination.count}
								onChange={pagination.onChange}
								compact={compact}
							/>
						}
					</ColumnContainer>
				</div>
			</WithRefetch>
		</ErrorBoundary>
	)
})
