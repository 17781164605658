import { ExportDataDecoraterPropertyModel } from "services/tenantManagementService"
import { Subtract } from "utility-types"
import { EntityPrefixEnum } from "utils/commonHelper"
import { CellComponent, EmptyCallback } from "tabulator-tables"

/** If there is a need for horizontal empty space inside of a table,
 * for example when grouping columns we need first column to have left margin so emptySpaceColumnId column is added with some minWidth.  */
export const emptySpaceColumnId = 'empty_space';

export enum FieldTypeEnum {
	Semaphore = 'Semaphore',
	MapOption = 'MapOption',
	Options = 'Options',
	Option = 'Option',
	Enums = 'Enums',
	Boolean = 'Boolean',
	Number = 'Number',
	Currency = 'Currency',
	String = 'String',
	Date = 'Date',
	FormattedReference = 'FormattedReference', // for references that are formatted using EntityPrefixEnum
	None = 'None' // filter icon will be hidden
}

// added undefined and number as return types
type CustomFormatter =/*Formatter*/
'plaintext'
| 'textarea'
| 'html'
| 'money'
| 'image'
| 'datetime'
| 'datetimediff'
| 'link'
| 'tickCross'
| 'color'
| 'star'
| 'traffic'
| 'progress'
| 'lookup'
| 'buttonTick'
| 'buttonCross'
| 'rownum'
| 'handle'
| 'rowSelection'
| 'responsiveCollapse'
| ((cell: CellComponent, formatterParams: {}, onRendered: EmptyCallback) => string | HTMLElement | undefined | number | boolean);

export type BaseColumnModel = {
	field: string
	fieldType: FieldTypeEnum
	visible?: boolean
	title: string
	hozAlign?: 'left' | 'center' | 'right'
	disableSort?: boolean
	disableFilter?: boolean
	formatter?: CustomFormatter
	formatterParams?: any
	notInModel?: boolean // used only to mark client side calculated values for easier search in future
	editor?: any
	editable?: any
	tooltip?: string | boolean | ((event: MouseEvent, cell: CellComponent, onRender: () => void) => string | any) // any added because of useVisibleColumns
	width?: number
	frozen?: boolean; // freeze the column so it is always visible while horizontal scrolling

	bottomCalc?: any
	bottomCalcFormatter?: CustomFormatter

	// backend specific
	dbFilterFieldPath?: string // DB field or path such as Status.Name for filtering
	dbExportFieldPath?: string | null // Db field or path such as Status.Name for export
	format?: string // for backend to format dates, FormattedReference, etc.
	displayNames?: string[] // for columns with decorator on server: userId -> username
	decoraterProperties?: ExportDataDecoraterPropertyModel[]// for columns with decorator on server: userId -> username
	validator?: any

	entityPrefix?: EntityPrefixEnum // field is required if FieldTypeEnum.FormattedReference
	options?: any[] // field is required if FieldTypeEnum.Options || Option
	getItemId?: (option: any) => number | string // field is required if FieldTypeEnum.Options || Option
	getItemText?: (option: any) => string // field is required if FieldTypeEnum.Options || Option
	columns?: BaseColumnModel[]
}

export type TableColumnType = Subtract<BaseColumnModel, { field: string, visible?: boolean }> & {
	field?: string
}

export type TableColumnsType = {
	[key in string]: TableColumnType
}
