import { GenericColumnModel } from "../models"
import styles from './footer.module.scss'
import { Row } from "./Row"
import { ColumnWidthMap } from "../CustomTable"

type Props = {
	columns: GenericColumnModel[]
	columnWidthMap: ColumnWidthMap
}

export const Footer = ({ columns, columnWidthMap }: Props) => {
	const hasFooter = columns.some((column) => column.footerValue !== undefined);

	if (!hasFooter) {
		return <></>
	}

	return (
		<div className={styles.container}>
			<Row
				columns={columns}
				columnWidthMap={columnWidthMap}
			/>
		</div>
	)
}
