import { AutoComplete } from "components/Form"
import { useMemo } from "react"
import { FormatterCell, OptionColumnModel } from "../../models"

type Props = {
	column: OptionColumnModel<any>
	value: string
	onChange(value: string): void
	onBlur(): void
	loading: boolean
	formatterCell: FormatterCell
}

export const OptionEditableCell = ({ column, value, onChange, onBlur, loading, formatterCell }: Props) => {
	const specificPropsMemo = useMemo(
		() => {
			const { items, getItems, getItemText, getItemId } = column;
			const newItems = getItems ? getItems(formatterCell) : items;

			return {
				items: newItems,
				getItems,
				getItemText,
				getItemId
			}
		},
		[column, formatterCell]
	)

	return (
		<AutoComplete
			value={value}
			onChange={onChange}
			onBlur={onBlur}
			{...specificPropsMemo}
			loading={loading}
			size='small'
			focus
		/>
	)
}
