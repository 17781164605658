import { Input } from "components/Form"

type Props = {
	value: string
	onChange(value: string): void
	onBlur(): void
	loading: boolean
}

export const StringEditableCell = ({ value, onChange, onBlur, loading }: Props) => {
	return (
		<Input
			value={value}
			onChange={onChange}
			onBlur={onBlur}
			loading={loading}
			hideMaxLength
			size='small'
			focus
		/>
	)
}
