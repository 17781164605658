import { useCallback, useState, memo, useMemo, useRef, useEffect } from 'react'
import { GenericColumnModel, BooleanColumnModel, FormatterCell } from '../models'
import styles from './body.module.scss'
import { EditableCell } from './EditableCell/EditableCell'
import { ChangeArrowsIcon } from 'components/icons/icons'
import { Tooltip } from '../Tooltip/Tooltip'
import { cellFormatter } from './cellFormatter'

type Props = {
	column: GenericColumnModel
	value: any
	columnWidth: number
	rowData: any
	cellEdited?: (rowData: any, columnId: string, value: any) => Promise<void>
}

const CellWithoutMemo = ({ column, value, columnWidth, rowData, cellEdited }: Props) => {
	// TODO:code_improvements Cell now contains some code that is specific to its column model, so consider creating of BooleanCell, OptionCell, etc.
	const isBooleanColumn = column instanceof BooleanColumnModel;

	const formatterCell: FormatterCell = useMemo(
		() => ({
			value,
			rowData
		}),
		[value, rowData]
	)

	const isCellEditable = useMemo(
		() => column.editable?.(formatterCell) || false,
		[column, formatterCell]
	)

	const contentRef = useRef<HTMLDivElement>(null);
	const [isEditActive, setIsEditActive] = useState((isBooleanColumn && isCellEditable) ? true : false);
	const [saving, setSaving] = useState(false);
	const [isEllipsisActive, setIsEllipsisActive] = useState(false);
	const [isTooltipVisible, setIsTooltipVisible] = useState(false);
	const [position, setPosition] = useState({ x: 0, y: 0 });

	useEffect(
		() => {
			setIsEditActive(isBooleanColumn && !!isCellEditable);
		},
		[isBooleanColumn, isCellEditable]
	)

	useEffect(
		() => {
			const content = contentRef.current;
			if (content) {
				setIsEllipsisActive(content.scrollWidth > content.clientWidth);
			}
		},
		[value, columnWidth]
	)

	const clickCallback = useCallback(
		(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
			if (isBooleanColumn) {
				return;
			}

			if (!isCellEditable || saving) {
				return;
			}

			// don't select row
			e.stopPropagation();
			setIsEditActive(true);
		},
		[isBooleanColumn, saving, isCellEditable]
	)

	const onChangeCompleteCallback = useCallback(
		async (columnId: string, value: any) => {
			if (cellEdited && rowData[columnId] !== value) {
				setSaving(true);
				await cellEdited(rowData, columnId, value);
				setSaving(false);
			}

			if (!isBooleanColumn) {
				setIsEditActive(false);
			}
		},
		[cellEdited, rowData, isBooleanColumn]
	)

	const styleMemo = useMemo(
		() => !isEditActive && column.getStyle ? column.getStyle(value) : {},
		[value, isEditActive, column]
	)

	const showTooltipCallback = useCallback(
		(e: React.MouseEvent) => {
			if (!isEllipsisActive) {
				return;
			}

			setPosition({ x: e.clientX + 12, y: e.clientY + 12 });
			setIsTooltipVisible(true);
		},
		[isEllipsisActive]
	)

	const hideTooltipCallback = useCallback(
		() => setIsTooltipVisible(false),
		[]
	)

	const formattedCell = useMemo(
		() => cellFormatter(column, formatterCell, isCellEditable),
		[column, formatterCell, isCellEditable]
	)

	const className = `${styles.cell} ${isCellEditable && isEditActive ? styles.editing : ''} ${!!column.frozen ? styles.frozen : ''}`;

	return (
		<div className={className} style={{ width: `${columnWidth}px`, ...styleMemo }} onClick={clickCallback} data-type='cell'>
			{(isEditActive || (isBooleanColumn && isCellEditable)) ?
			// TODO: Editable Cell nema data-columnId setovan i frozen ne radi
				<EditableCell
					column={column}
					value={value}
					onChangeComplete={onChangeCompleteCallback}
					loading={saving}
					formatterCell={formatterCell}
				/>
				:
				<div ref={contentRef} className={`${styles.content} ${styles[`align_${column.align}`] || ''}`} onMouseOver={showTooltipCallback} onMouseLeave={hideTooltipCallback}>
					<span className={styles.inner} data-columnid={column.id}>
						{formattedCell}
					</span>
					{isEllipsisActive && isTooltipVisible &&
						<Tooltip
							content={formattedCell.toString()}
							topPosition={position.y}
							leftPosition={position.x}
						/>
					}
					{isCellEditable && !isBooleanColumn && <ChangeArrowsIcon className={styles.hover_icon} width={12} height={12} fill='#fff' title={`Change ${column.title.toLowerCase()}`} />}
				</div>
			}
		</div>
	)
}

export const Cell = memo(CellWithoutMemo);
