import { memo, useMemo } from 'react'
import { FormatterCell, GenericColumnModel } from '../models'
import styles from './footer.module.scss'

type Props = {
	column: GenericColumnModel
	columnWidth: number
}

const CellWithoutMemo = ({ column, columnWidth }: Props) => {
	const className = `${styles.cell} ${!!column.frozen ? styles.frozen : ''}`;

	const cellData = useMemo(
		() => {
			if (!column.footerValue) {
				return '';
			}

			if (column.footerFormatter) {
				const cell: FormatterCell = {
					value: column.footerValue(),
					rowData: [] // INFO: nije implementirano posto nije postojala potreba
				}

				return column.footerFormatter(cell);
			}

			return column.footerValue();
		},
		[column]
	)

	return (
		<div className={className} style={{ width: `${columnWidth}px` }} data-type='cell'>
			<div className={`${styles.content} ${styles[`align_${column.align}`] || ''}`}>
				<span className={styles.inner} data-columnid={column.id}>
					{cellData}
				</span>
			</div>
		</div>
	)
}

export const Cell = memo(CellWithoutMemo);
