import { useCallback, useEffect, useState } from 'react';
import { Input, SelectionType } from '../';
import { ControlsCommonProps } from '../../fields';
import { ClockIcon } from 'components/icons/icons';
import { formatTime } from 'utils/dateTimeUtils';
import { OverlaySpinner } from 'components/Spinner';
import styles from './timePicker.module.scss';
import { convertSanitizeTimeToTicks, decreaseHours, decreaseMinutes, increaseHours, increaseMinutes, sanitizeTimeInput, togglePeriod } from './timePickerHelper';

export type TimePickerProps = ControlsCommonProps<number | undefined> & {
	placeholder?: string
	size?: 'medium' | 'small'
	focus?: boolean
	loading?: boolean
};

// TODO: all controls are controlled components, so check if TimePicker should be controlled
// small issues and need to support localisation 12h vs 24h using Intl.DateTimeFormat
export const TimePicker = ({ value, onChange, disabled, onBlur, placeholder, size = 'medium', focus = false, loading }: TimePickerProps) => {
	const [inputValue, setInputValue] = useState(formatTime(value) || '--:-- --');
	const [selection, setSelection] = useState<SelectionType>({ start: 0, end: 2 });

	useEffect(
		() => {
			setInputValue(formatTime(value) || '--:-- --');
		},
		[value]
	)

	const onChangeCallback = useCallback(
		(newValue: string | undefined) => {
			if (!newValue) {
				setInputValue('--:-- --');
				onChange && onChange(undefined);
				return;
			}

			const sanitizedValue = sanitizeTimeInput(newValue);
			setInputValue(sanitizedValue);

			const ticks = convertSanitizeTimeToTicks(sanitizedValue);
			onChange && onChange(ticks);
		},
		[onChange]
	)

	const handleClickCallback = useCallback(
		(e: React.MouseEvent<HTMLInputElement>) => {
			const position = e.currentTarget.selectionStart || 0;

			if (position <= 2 || position >= 8) {
				e.currentTarget.setSelectionRange(0, 2);
			} else if (position >= 3 && position <= 5) {
				e.currentTarget.setSelectionRange(3, 5);
			} else if (position >= 6 && position < 8) {
				e.currentTarget.setSelectionRange(6, 8);
			} else {
				e.currentTarget.setSelectionRange(e.currentTarget.value.length, e.currentTarget.value.length);
			}
		},
		[]
	)

	const handleKeyDownCallback = useCallback(
		(eventKey: string, e: React.KeyboardEvent<HTMLInputElement>) => {
			const position = e.currentTarget.selectionStart || 0;
			let newValue = inputValue;

			if (eventKey === 'ArrowRight') {
				e.preventDefault();

				if (position < 2) {
					e.currentTarget.setSelectionRange(3, 5);
				} else if (position < 5) {
					e.currentTarget.setSelectionRange(6, 8);
				} else {
					e.currentTarget.setSelectionRange(0, 2);
				}
			} else if (eventKey === 'ArrowLeft') {
				e.preventDefault();

				if (position <= 2) {
					e.currentTarget.setSelectionRange(6, 8);
				} else if (position <= 5) {
					e.currentTarget.setSelectionRange(0, 2);
				} else {
					e.currentTarget.setSelectionRange(3, 5);
				}
			} else if (eventKey === 'ArrowUp' || eventKey === 'ArrowDown') {
				e.preventDefault();

				if (position <= 2) {
					newValue = eventKey === 'ArrowUp' ? increaseHours(newValue) : decreaseHours(newValue);
					setSelection({ start: 0, end: 2 });
				} else if (position >= 3 && position <= 5) {
					newValue = eventKey === 'ArrowUp' ? increaseMinutes(newValue) : decreaseMinutes(newValue);
					setSelection({ start: 3, end: 5 });
				} else {
					newValue = togglePeriod(newValue);
					setSelection({ start: 6, end: 8 });
				}

				setInputValue(newValue);
				const ticks = convertSanitizeTimeToTicks(newValue);
				onChange && onChange(ticks);
			}
		},
		[inputValue, onChange]
	)

	return (
		<div className={styles.container}>
			<Input
				value={inputValue}
				onChange={onChangeCallback}
				onBlur={onBlur}
				onClick={handleClickCallback}
				onKeyDown={handleKeyDownCallback}
				disabled={disabled || loading}
				hideMaxLength
				focus={focus}
				size={size}
				placeholder={placeholder}
				selection={selection}
			/>
			<ClockIcon
				className={styles.icon}
				width={16}
				height={16}
				fill="currentColor"
			/>
			{/* loading */}
			{loading && <OverlaySpinner size={20} useBrandColor />}
		</div>
	)
}
