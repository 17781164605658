import { memo, MouseEvent, PointerEvent, useCallback, useMemo, useRef } from 'react'
import styles from './body.module.scss'
import { Cell } from './Cell'
import { GenericColumnModel, InteractionManager } from '../models'
import { ColumnWidthMap } from '../CustomTable'

type Props = {
	columns: GenericColumnModel[]
	rowData: any
	onClick: (rowData: any) => void
	cellEdited?: (rowData: any, columnId: string, value: any) => Promise<void>
	isSelected: boolean
	columnWidthMap: ColumnWidthMap
	interactionManager: InteractionManager
}

const RowWithoutMemo = ({ columns, rowData, onClick, cellEdited, isSelected, columnWidthMap, interactionManager }: Props) => {
	const rowRef = useRef<HTMLDivElement | null>(null);

	const columnsMemo = useMemo(
		() => columns.filter(column => column.frozen !== 'end'),
		[columns]
	)

	const frozenRightColumns = useMemo(
		() => columns.filter(column => column.frozen === 'end'),
		[columns]
	)

	const cellsContent = useCallback(
		(newColumns: GenericColumnModel[]) => {
			return newColumns.map((column) => {
				return (
					<Cell
						column={column}
						key={column.id}
						value={rowData[column.id]}
						columnWidth={columnWidthMap[column.id]}
						rowData={rowData}
						cellEdited={cellEdited}
					/>
				)
			})
		},
		[rowData, columnWidthMap, cellEdited]
	)

	// just to make onAuxClick trigger on middle click
	const onPointerDownCallback = useCallback(
		(e: PointerEvent) => {
			if (e.button === 1) {
				e.preventDefault();
			}
		},
		[]
	)

	const onClickCallback = useCallback(
		() => onClick(rowData),
		[onClick, rowData]
	)

	const onMiddleClickCallback = useCallback(
		(e: MouseEvent) => {
			if (e.button === 1) {
				interactionManager.middleClick?.(rowData);
			}
		},
		[interactionManager, rowData]
	)

	const onDoubleClickCallback = useCallback(
		() => interactionManager.doubleClick?.(rowData),
		[interactionManager, rowData]
	)

	return (
		<div className={styles.row_wrapper}>
			<div
				className={`${styles.row} ${isSelected ? styles.selected : ''}`}
				onClick={onClickCallback}
				onPointerDown={onPointerDownCallback}
				onAuxClick={onMiddleClickCallback}
				onDoubleClick={onDoubleClickCallback}
				ref={rowRef}
			>
				{columnsMemo.length > 0 && (
					frozenRightColumns.length > 0 ?
						<div className={styles.cells_wrapper}>
							{cellsContent(columnsMemo)}
						</div>
						:
						cellsContent(columnsMemo)
				)}
				{frozenRightColumns.length > 0 &&
					cellsContent(frozenRightColumns)
				}
			</div>
		</div>
	)
}

export const Row = memo(RowWithoutMemo);
