import { useCallback, useEffect, useState } from 'react';
import Spinner, { ClipSpinner } from 'components/Spinner';
import styles from './withFetch.module.scss'

type Props = {
    children: any
    fetchFunction(): Promise<any>
    size?: 40 | 70,
    isLoading?: boolean
	withBackground?: boolean
}

// DEPRECATED: after BaseTable is deleted, this should be also deleted
const WithRefetch = ({ children, fetchFunction, size = 70, isLoading, withBackground = true  }: Props) => {
    const [fetching, setFetching] = useState(true);

    const fetchData = useCallback(
        async () => {
            setFetching(true);
            await fetchFunction();
            setFetching(false);
        },
        [fetchFunction]
    )

    useEffect(
        () => {
            fetchData();
        },
        [fetchData]
    )

    return (
        <div style={{ position: 'relative' }}>
            {children}
            {((fetching || isLoading)  &&
                <div className={`${styles.container} ${withBackground ? styles.background : ''}`}>
                    <Spinner>
                        <ClipSpinner size={size} />
                    </Spinner>
                </div>
            )}
        </div>
    )
}

export default WithRefetch;
