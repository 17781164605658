import { GenericSortModel } from "services/tenantManagementService";
import { BaseColumnModel, FieldTypeEnum } from "./BaseColumnModel";
import { customTitleFormatter } from "./HeaderCell/customTitleFormatter";
import { FilterModel } from "./Filter/Filter";
import { noop } from "utils/commonHelper";

export const convertBaseColumnHelper = (column: BaseColumnModel, sort: GenericSortModel, filters: FilterModel[], onFilterClickCallback: (field: string) => void, onSortClickCallback: (field: string) => void) => {
	// filter out fields that are not used by tabulator, because it shows thousands of warnings in console
	const {
		fieldType, dbFilterFieldPath, dbExportFieldPath, getItemText, getItemId, options, entityPrefix,
		format, displayNames, decoraterProperties, disableFilter, disableSort, notInModel, columns,
		...tabulatorFields
	} = column;
	const convertedColumn: /*ColumnDefinition*/ any = {
		...tabulatorFields,
		titleFormatter: customTitleFormatter,
		titleFormatterParams: () => {
			return {
				column,
				sort,
				filters,
				onFilterClick: column.fieldType !== FieldTypeEnum.None ? onFilterClickCallback : noop,
				onSortClick: (column.disableSort || column.fieldType === FieldTypeEnum.None) ? noop : onSortClickCallback
			}
		},
		headerClick: noop,
		headerSort: false,
		columns: columns?.map((childColumn) => convertBaseColumnHelper(childColumn, sort, filters, onFilterClickCallback, onSortClickCallback))
	}

	return convertedColumn;
}
