import store from 'base/reducer/store';
import { tryCatchJsonByAction } from 'utils/fetchUtils';
import { ConfigureViewType } from './reducer';
import { ActionType, FetchingActionType } from 'features/Persist/actionTypes';
import { getUserTableViewConfigAction, updateUserTableViewConfigAction } from 'features/UserTableViewConfig/action';

export type ConfigureViewTableActionType = ActionType & {
	key: string;
	visibleColumns: string[];
};

export type ConfigureViewActionType = ActionType & {
	configureView: ConfigureViewType
};

const viewType = 'Table';

// async api calls

const getConfigureViewAction = () => {
	return getUserTableViewConfigAction(viewType);
}

const updateConfigureViewAction = () => {
	const state = store.getState();
	const configureView = state.persistedConfigureView.value;

	return updateUserTableViewConfigAction(viewType, configureView);
}

export const persistConfigureViewAction = async () => {
	const state = store.getState();

	if (state.persistedConfigureView.fetching) {
		return;
	}

	store.dispatch(setConfigureViewFetchingAction(true));
	const response = await tryCatchJsonByAction(getConfigureViewAction);
	if (response.success && response.value) {
		store.dispatch(setConfigureViewAction(response.value.content));
	} else {
		store.dispatch(setConfigureViewFetchingAction(false));
	}
}

// persist configure view redux
export const SET_CONFIGURE_VIEW = 'SET_CONFIGURE_VIEW';
export const SET_CONFIGURE_VIEW_TABLE = 'SET_CONFIGURE_VIEW_TABLE';
export const CONFIGURE_VIEW_FETCHING = 'CONFIGURE_VIEW_FETCHING';

const setConfigureViewFetchingAction = (fetching: boolean): FetchingActionType => {
	return {
		type: CONFIGURE_VIEW_FETCHING,
		fetching
	}
}

export const setConfigureViewTableAction = async (key: string, visibleColumns: string[]) => {
	store.dispatch({
		type: SET_CONFIGURE_VIEW_TABLE,
		key,
		visibleColumns,
	} as ConfigureViewTableActionType);

	return await updateConfigureViewAction();
};

const setConfigureViewAction = (configureView: ConfigureViewType): ConfigureViewActionType => {
	return {
		type: SET_CONFIGURE_VIEW,
		configureView
	};
};
