import { FetchingActionType } from 'features/Persist/actionTypes';
import { PersistValueReducer } from 'features/Persist/reducerTypes';
import { SET_COLUMNS_ORDER_TABLE_CONFIG, SET_COLUMNS_WIDTH_TABLE_CONFIG, SET_TABLE_CONFIG, TablesConfigActionType, TABLE_CONFIG_FETCHING, ColumnsOrderActionType, ColumnsWidthActionType, SET_FILTERS_TABLE_CONFIG, FiltersActionType, SortActionType, SET_SORT_TABLE_CONFIG } from './action';
import { FilterType, SortType } from 'components/Table';

export type ColumnsOrderType = string[]
export type ColumnsWidthType = { [columnId: string]: number }

export type TableConfig = {
	columnsOrder?: ColumnsOrderType
	columnsWidth?: ColumnsWidthType
	filters?: FilterType[]
	sort?: SortType
}

export type TablesConfig = {
	[tableId: string]: TableConfig | undefined
}

const initialState = new PersistValueReducer<TablesConfig>();

export const tablesConfigReducer = (
	state = initialState,
	action: TablesConfigActionType | ColumnsOrderActionType | ColumnsWidthActionType | FiltersActionType | SortActionType | FetchingActionType
): PersistValueReducer<TablesConfig> => {
	switch (action.type) {
		case TABLE_CONFIG_FETCHING:
			const { fetching } = action as FetchingActionType;

			return {
				...state,
				fetching
			}
		case SET_TABLE_CONFIG:
			const { tablesConfig } = action as TablesConfigActionType;

			return {
				value: tablesConfig,
				fetching: false,
				isInitialized: true
			}
		case SET_COLUMNS_ORDER_TABLE_CONFIG: {
			const { tableId, columnsOrder } = action as ColumnsOrderActionType;

			return {
				value: {
					...state.value,
					[tableId]: {
						...state.value[tableId],
						columnsOrder
					},
				},
				fetching: false,
				isInitialized: true
			}
		}
		case SET_COLUMNS_WIDTH_TABLE_CONFIG: {
			const { tableId, columnsWidth } = action as ColumnsWidthActionType;

			return {
				value: {
					...state.value,
					[tableId]: {
						...state.value[tableId],
						columnsWidth
					},
				},
				fetching: false,
				isInitialized: true
			}
		}
		case SET_FILTERS_TABLE_CONFIG: {
			const { tableId, filters } = action as FiltersActionType;

			return {
				value: {
					...state.value,
					[tableId]: {
						...state.value[tableId],
						filters
					},
				},
				fetching: false,
				isInitialized: true
			}
		}
		case SET_SORT_TABLE_CONFIG: {
			const { tableId, sort } = action as SortActionType;

			return {
				value: {
					...state.value,
					[tableId]: {
						...state.value[tableId],
						sort
					},
				},
				fetching: false,
				isInitialized: true
			}
		}
		default:
			return state;
	}
};
